import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import dashboardReducer from "./slices/dashboard";
import shopReducer from "./slices/shop";
import paymentReducer from "./slices/payment";
import logReducer from "./slices/log";
import supportReducer from "./slices/support";
import globalReducer from "./slices/global";

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};
const globalPersistConfig = {
  key: "global",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sales"],
};

const rootReducer = combineReducers({
  dashboard: dashboardReducer,
  shop: shopReducer,
  log: logReducer,
  support: supportReducer,
  payment: paymentReducer,
  global: persistReducer(globalPersistConfig, globalReducer),
});

export { rootPersistConfig, rootReducer };
