import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import { Typography, Button, Stack } from "@mui/material";
import useAuth from "../hooks/useAuth";
import { PATH_DASHBOARD } from "../routes/paths";
import { CheckOnboardingDone } from "../utils/apis";

const NoticeStyle = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  justifyContent: "center",
  padding: 5,
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    padding: 5,
  },
}));

const useBasePath = () => {
  const location = useLocation();
  const params = useParams();

  return Object.values(params).reduce(
    (path, param) => path.replace(`/${param}`, ""),
    location.pathname
  );
};
export default function NotificationBar() {
  const pathname = useBasePath();
  const { isAuthenticated } = useAuth();
  const isDone = CheckOnboardingDone();
  const [notice, setNotice] = useState({
    message: "",
    button: "",
    path: "",
    color: "",
  });

  useEffect(() => {
    setNotice({ message: "", button: "", path: "", color: "" });
    if (
      isAuthenticated &&
      !isDone &&
      pathname !== "/dashboard" &&
      pathname !== "/" &&
      pathname !== "/settings"
    ) {
      setNotice({
        message: "Let us know how to pay you.",
        button: "Provide Account Info",
        path: PATH_DASHBOARD.general.settings,
        color: "success",
      });
    }
  }, [pathname, isDone, isAuthenticated]);

  return (
    notice.message && (
      <NoticeStyle sx={{ bgcolor: `${notice.color}.light` }}>
        <Stack
          direction={{ md: "row", xs: "column" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="body2" sx={{ color: "common.black" }}>
            {notice.message}
          </Typography>
          <Button color="secondary" href={notice.path}>
            {notice.button}
          </Button>
        </Stack>
      </NoticeStyle>
    )
  );
}
