// components
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsIcon from "@mui/icons-material/Settings";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ReportIcon from "@mui/icons-material/Report";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.root,
        icon: <DashboardIcon />,
      },
      {
        title: "Account Settings",
        path: PATH_DASHBOARD.general.settings,
        icon: <SettingsIcon />,
      },
    ],
  },
  {
    subheader: "business",
    items: [
      {
        title: "Shops",
        path: PATH_DASHBOARD.business.shops,
        icon: <StorefrontIcon />,
      },
      {
        title: "Payments",
        path: PATH_DASHBOARD.business.payments,
        icon: <StorefrontIcon />,
      },
    ],
  },
  {
    subheader: "miscellaneous",
    items: [
      {
        title: "Support Messages",
        path: PATH_DASHBOARD.misc.support,
        icon: <ContactSupportIcon />,
      },
      {
        title: "Activity Logs",
        path: PATH_DASHBOARD.misc.log,
        icon: <ReportIcon />,
      },
    ],
  },
];

export default sidebarConfig;
