import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import useAuth from "../hooks/useAuth";
import { firebaseConfig, vapidKey } from "../utils/apis";

// ----------------------------------------------------------------------

export default function FirebaseInit() {
  const { isAuthenticated, saveToken, logout, initialize } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
    getPerformance(app);

    const UpdateData = (data) => {
      if (data === "PROFILE") {
        // updated data
        initialize();
      }
    };

    /* const notifyData = async (data) => {
      try {
        if (Object.prototype.hasOwnProperty.call(data, "title")) {
          let actionClick = null;
          const payload = JSON.parse(data.payload);
          if (
            payload &&
            Object.prototype.hasOwnProperty.call(payload, "data_type")
          ) {
            switch (payload.data_type) {
              case "SALES":
                actionClick = () =>
                  navigate(
                    `${PATH_DASHBOARD.subpages.sale}${payload.id || ""}`
                  );
                break;
              case "INVOICE":
                actionClick = () =>
                  navigate(
                    `${PATH_DASHBOARD.subpages.sale}${payload.id || ""}`
                  );
                break;
              default:
                break;
            }
          }
          const action = (key) => (
            <>
              {actionClick && (
                <Button
                  onClick={() => {
                    actionClick();
                    closeSnackbar(key);
                  }}
                >
                  View
                </Button>
              )}
              <Button
                onClick={() => {
                  closeSnackbar(key);
                }}
              >
                Dismiss
              </Button>
            </>
          );
          enqueueSnackbar(`${data.title}\n${data.message}`, {
            variant: "info",
            action,
          });
        }
      } catch (err) {
        // console.log(err);
      }
    }; */

    const CheckFirebaseMessage = (payload) => {
      /* if (Object.prototype.hasOwnProperty.call(payload, "notification")) {
        const { body, title } = payload.notification;
        let actionClick = null;
        if (
          Object.prototype.hasOwnProperty.call(payload, "data") &&
          payload.data
        ) {
          const message = JSON.parse(payload.data.message);
          if (Object.prototype.hasOwnProperty.call(message, "type")) {
            const { type, data } = message;
            switch (type) {
              case "NAVIGATE":
                // logut user.
                actionClick = () => window.open(data);
                break;
              default:
                break;
            }
          }
        }
        const action = (key) => (
          <>
            {actionClick && (
              <Button
                onClick={() => {
                  actionClick();
                  closeSnackbar(key);
                }}
              >
                View
              </Button>
            )}
            <Button
              onClick={() => {
                closeSnackbar(key);
              }}
            >
              Dismiss
            </Button>
          </>
        );
        enqueueSnackbar(`${title}\n${body}`, { variant: "info", action });
      } */

      if (
        Object.prototype.hasOwnProperty.call(payload, "data") &&
        Object.prototype.hasOwnProperty.call(payload.data, "message")
      ) {
        const message = JSON.parse(payload.data.message);
        if (Object.prototype.hasOwnProperty.call(message, "type")) {
          const { type, data } = message;
          switch (type) {
            case "LOGOUT":
              // logut user.
              logout();
              break;
            case "UPDATE":
              UpdateData(data);
              break;
            /* case "NOTIFY":
              notifyData(data);
              break; */
            default:
              break;
          }
        }
      }
    };
    if (isAuthenticated && "Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          const messaging = getMessaging();
          getToken(messaging, {
            vapidKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                saveToken(currentToken);
              }
            })
            .catch((err) => {
              console.log(err);
            });

          onMessage(messaging, (payload) => {
            CheckFirebaseMessage(payload);
          });
        }
      });
    }
  }, [
    isAuthenticated,
    saveToken,
    enqueueSnackbar,
    closeSnackbar,
    logout,
    navigate,
    initialize,
  ]);

  return null;
}
