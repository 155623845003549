import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default function Logo({ disabledLink = false, sx }) {
  const logo = (
    <Box
      component="img"
      src="/logo/logo-black.png"
      sx={{ width: "auto", height: 40, ...sx }}
    />
  );

  if (disabledLink) {
    // eslint-disable-next-line
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
