// material
import { Box } from "@mui/material";

export default function Referral({ ...other }) {
  return (
    <Box {...other}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 765 549"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M163.673 360.464C171.597 412.074 138.724 487.961 204.469 516.749C292.271 555.22 347.293 457.857 418.621 440.037C473.046 426.423 567.105 525.238 634.884 462.507C702.663 399.776 588.52 314.13 584.222 263.086C579.969 212.607 674.472 124.529 598.677 70.8537C524.274 18.2335 439.593 118.747 369.228 137.516C306.665 154.174 154.801 74.9532 109.645 152.614C66.8762 226.145 156.025 310.628 163.673 360.464Z"
          fill="#407BFF"
        />
        <path
          opacity="0.9"
          d="M163.673 360.464C171.597 412.074 138.724 487.961 204.469 516.749C292.271 555.22 347.293 457.857 418.621 440.037C473.046 426.423 567.105 525.238 634.884 462.507C702.663 399.776 588.52 314.13 584.222 263.086C579.969 212.607 674.472 124.529 598.677 70.8537C524.274 18.2335 439.593 118.747 369.228 137.516C306.665 154.174 154.801 74.9532 109.645 152.614C66.8762 226.145 156.025 310.628 163.673 360.464Z"
          fill="white"
        />
        <path
          d="M530.577 332.073H646.357C650.625 332.073 654.718 333.769 657.736 336.787C660.753 339.804 662.449 343.897 662.449 348.165V436.167C662.449 440.434 660.753 444.527 657.736 447.545C654.718 450.563 650.625 452.259 646.357 452.259H558.937C558.095 459.138 554.771 465.473 549.588 470.076C544.406 474.678 537.722 477.23 530.791 477.253C530.149 477.253 529.522 477.253 528.879 477.161C533.468 471.196 537.093 462.568 539.51 452.274H530.654C526.386 452.274 522.293 450.578 519.275 447.561C516.257 444.543 514.562 440.45 514.562 436.182V348.165C514.561 343.911 516.246 339.829 519.248 336.814C522.249 333.798 526.322 332.094 530.577 332.073V332.073Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M530.577 332.073H646.357C650.625 332.073 654.718 333.769 657.736 336.787C660.753 339.804 662.449 343.897 662.449 348.165V436.167C662.449 440.434 660.753 444.527 657.736 447.545C654.718 450.563 650.625 452.259 646.357 452.259H558.937C558.095 459.138 554.771 465.473 549.588 470.076C544.406 474.678 537.722 477.23 530.791 477.253C530.149 477.253 529.522 477.253 528.879 477.161C533.468 471.196 537.093 462.568 539.51 452.274H530.654C526.386 452.274 522.293 450.578 519.275 447.561C516.257 444.543 514.562 440.45 514.562 436.182V348.165C514.561 343.911 516.246 339.829 519.248 336.814C522.249 333.798 526.322 332.094 530.577 332.073V332.073Z"
          fill="white"
        />
        <path
          d="M525.101 366.292L519.38 356.609L522.959 350.49C523.192 350.094 523.331 349.65 523.368 349.192C523.406 348.734 523.339 348.273 523.173 347.844L522.898 347.156C522.684 346.582 522.303 346.086 521.804 345.732C521.305 345.377 520.711 345.181 520.099 345.167L514.485 345.014C513.915 344.994 513.351 345.134 512.856 345.418C512.362 345.702 511.956 346.118 511.686 346.62L510.554 348.701C510.28 349.202 510.128 349.761 510.109 350.332C510.091 350.904 510.206 351.471 510.447 351.989L512.864 357.206L517.345 369.198L525.101 366.292Z"
          fill="#7F3E3B"
        />
        <path
          d="M570.669 364.594L537.98 388.38L522.684 359.653L513.965 363.431C513.965 363.431 527.411 406.965 535.227 405.206C543.044 403.447 565.101 392.541 572.275 385.061C575.901 381.282 578.654 374.353 575.075 365.787C574.91 365.377 574.651 365.01 574.321 364.717C573.99 364.423 573.596 364.21 573.169 364.094C572.742 363.978 572.294 363.963 571.86 364.05C571.427 364.137 571.019 364.323 570.669 364.594V364.594Z"
          fill="#407BFF"
        />
        <path
          d="M582.876 307.951C582.876 307.951 571.343 311.01 576.681 324.915C576.681 324.915 582.433 323.385 583.366 319.683C584.299 315.981 585.186 310.75 585.186 310.75L582.876 307.951Z"
          fill="#263238"
        />
        <path
          opacity="0.3"
          d="M582.876 307.951C582.876 307.951 571.343 311.01 576.681 324.915C576.681 324.915 582.433 323.385 583.366 319.683C584.299 315.981 585.186 310.75 585.186 310.75L582.876 307.951Z"
          fill="black"
        />
        <path
          d="M610.15 363.217L589.071 362.529L580.352 363.783L579.159 379.676L596.154 385.321L614.28 381.711L615.229 366.399L613.928 363.34L610.15 363.217Z"
          fill="#7F3E3B"
        />
        <path
          d="M581.454 452.259H611.282C611.573 441.75 613.133 387.187 612.812 387.523C612.491 387.86 615.963 375.898 615.963 375.898V371.049L612.812 365.848C612.812 365.848 606.051 376.158 594.915 375.653C584.36 375.164 582.54 364.624 582.54 364.624L581.27 380.732L579.939 419.738L581.637 436.258L581.454 452.259Z"
          fill="#407BFF"
        />
        <path
          opacity="0.6"
          d="M581.454 452.259H611.282C611.573 441.75 613.133 387.187 612.812 387.523C612.491 387.86 615.963 375.898 615.963 375.898V371.049L612.812 365.848C612.812 365.848 606.051 376.158 594.915 375.653C584.36 375.164 582.54 364.624 582.54 364.624L581.27 380.732L579.939 419.738L581.637 436.258L581.454 452.259Z"
          fill="white"
        />
        <path
          d="M569.583 452.259H585.737C588.918 431.21 589.347 392.724 589.347 392.724C587.417 385.357 585.911 377.885 584.834 370.345C583.855 361.519 596.459 362.697 607.473 364.15C618.487 365.603 598.907 393.459 598.907 393.459C598.907 393.459 597.515 431.103 600.743 452.259H640.514C638.525 446.645 632.651 429.406 631.336 417.184C629.638 402.407 639.81 380.854 638.357 370.345C636.904 359.837 602.043 361.978 602.043 361.978C602.043 361.978 579.725 361.825 574.754 362.055C568.941 362.315 566.616 377.627 563.862 389.726C561.109 401.826 566.172 413.803 568.941 429.023C570.314 436.693 570.531 444.525 569.583 452.259Z"
          fill="#263238"
        />
        <path
          d="M587.388 361.932C594.639 361.932 602.119 361.932 602.119 361.932C602.119 361.932 606.57 361.657 612.352 361.779C614.662 363.309 617.706 367.011 615.289 374.751L610.241 376.693L611.159 381.665C611.159 381.665 605.347 390.843 598.922 394.361C598.922 393.749 598.922 393.413 598.922 393.413C598.922 393.413 618.517 365.558 607.488 364.104C596.459 362.651 583.87 361.473 584.849 370.299C585.926 377.839 587.432 385.311 589.362 392.678C589.362 392.678 589.362 392.831 589.362 393.091C586.302 390.323 583.625 385.443 581.713 379.6L583.778 373.879L579.189 371.37C579.581 369.205 580.55 367.186 581.993 365.525C583.436 363.864 585.3 362.623 587.388 361.932Z"
          fill="#407BFF"
        />
        <path
          d="M587.082 370.269C586.729 369.457 586.586 368.568 586.666 367.686C586.746 366.804 587.047 365.956 587.541 365.221C595.832 362.422 595.067 356.196 593.155 350.429L606.509 336.8C605.729 344.051 605.438 360.739 610.18 366.552V367.883C608.118 370.533 605.358 372.556 602.211 373.726C599.063 374.896 595.652 375.166 592.36 374.506C591.192 374.308 590.094 373.815 589.17 373.074C588.246 372.332 587.528 371.367 587.082 370.269V370.269Z"
          fill="#7F3E3B"
        />
        <path
          opacity="0.1"
          d="M593.155 350.429L603.771 339.599L606.219 340.486C606.142 341.787 606.096 343.209 606.081 344.708C604.735 347.462 601.492 351.332 593.966 353.274C593.747 352.312 593.477 351.362 593.155 350.429V350.429Z"
          fill="black"
        />
        <path
          d="M613.393 324.777C610.165 337.121 609.064 342.414 601.492 347.584C590.111 355.37 575.656 348.716 575.121 335.653C574.646 323.89 579.985 305.687 593.201 303.117C596.111 302.538 599.12 302.711 601.943 303.622C604.767 304.533 607.311 306.15 609.334 308.32C611.356 310.49 612.791 313.14 613.502 316.021C614.212 318.901 614.175 321.915 613.393 324.777Z"
          fill="#7F3E3B"
        />
        <path
          d="M583.978 327.959C582.615 330.103 580.968 332.053 579.083 333.756C579.525 334.227 580.065 334.595 580.665 334.836C581.265 335.076 581.91 335.183 582.555 335.148L583.978 327.959Z"
          fill="#630F0F"
        />
        <path
          d="M597.147 323.415C597.234 323.411 597.318 323.385 597.392 323.339C597.449 323.306 597.5 323.262 597.539 323.209C597.579 323.156 597.608 323.095 597.624 323.031C597.639 322.967 597.642 322.9 597.631 322.834C597.62 322.769 597.596 322.706 597.56 322.651C597.091 321.824 596.429 321.124 595.629 320.609C594.83 320.095 593.918 319.783 592.971 319.698C592.905 319.696 592.839 319.707 592.777 319.731C592.715 319.754 592.658 319.79 592.61 319.835C592.561 319.881 592.523 319.935 592.495 319.996C592.468 320.056 592.453 320.122 592.451 320.188C592.447 320.253 592.456 320.319 592.478 320.381C592.5 320.443 592.534 320.5 592.578 320.548C592.622 320.597 592.676 320.636 592.735 320.664C592.795 320.691 592.86 320.706 592.925 320.708C593.704 320.794 594.451 321.064 595.105 321.495C595.759 321.925 596.301 322.505 596.688 323.186C596.738 323.261 596.807 323.321 596.887 323.361C596.968 323.401 597.057 323.42 597.147 323.415Z"
          fill="#263238"
        />
        <path
          d="M576.682 319.698C576.762 319.721 576.846 319.721 576.926 319.698C577.597 319.299 578.353 319.065 579.132 319.014C579.911 318.964 580.691 319.099 581.408 319.408C581.528 319.468 581.667 319.479 581.796 319.439C581.924 319.399 582.032 319.311 582.097 319.194V319.194C582.128 319.134 582.147 319.068 582.152 319C582.157 318.933 582.149 318.865 582.128 318.801C582.106 318.737 582.072 318.677 582.027 318.627C581.983 318.576 581.928 318.535 581.867 318.505C581 318.12 580.054 317.946 579.107 318C578.16 318.053 577.239 318.331 576.421 318.811C576.304 318.876 576.216 318.984 576.176 319.112C576.136 319.241 576.147 319.379 576.207 319.5C576.254 319.583 576.323 319.652 576.407 319.698C576.491 319.745 576.586 319.766 576.682 319.76V319.698Z"
          fill="#263238"
        />
        <path
          d="M587.725 338.544C587.135 338.546 586.547 338.495 585.966 338.391C585.926 338.385 585.887 338.372 585.852 338.351C585.817 338.33 585.786 338.302 585.762 338.27C585.738 338.237 585.72 338.2 585.711 338.16C585.701 338.12 585.7 338.079 585.706 338.039C585.721 337.959 585.766 337.888 585.831 337.84C585.896 337.792 585.977 337.77 586.058 337.779C587.349 338.033 588.681 337.981 589.948 337.628C591.215 337.274 592.381 336.629 593.354 335.744C593.415 335.703 593.487 335.683 593.56 335.688C593.634 335.694 593.703 335.724 593.756 335.774C593.81 335.824 593.845 335.891 593.855 335.963C593.866 336.036 593.851 336.11 593.813 336.173C592.164 337.715 589.984 338.564 587.725 338.544Z"
          fill="#263238"
        />
        <path
          d="M609.156 334.429C607.663 332.733 606.554 330.733 605.907 328.568C605.261 326.403 605.09 324.123 605.408 321.886C605.408 321.886 600.437 316.792 600.682 311.346C600.682 311.346 599.152 315.691 600.682 318.781C600.682 318.781 587.787 317.848 588.093 311.346C588.093 311.346 587.236 314.62 589.179 316.318C589.179 316.318 577.722 316.792 579.282 310.857C580.842 304.922 589.806 302.031 589.806 302.031C589.806 302.031 598.357 294.765 610.181 307.599C610.181 307.599 624.101 316.287 614.234 330.788L609.156 334.429Z"
          fill="#263238"
        />
        <path
          d="M614.326 335.24C613.767 336.332 612.994 337.301 612.053 338.089C611.113 338.877 610.024 339.469 608.85 339.829C605.791 340.777 604.093 337.993 604.904 335.01C605.638 332.318 608.147 328.586 611.328 328.892C612.432 329.054 613.425 329.648 614.091 330.543C614.756 331.438 615.039 332.561 614.877 333.664C614.805 334.222 614.617 334.758 614.326 335.24V335.24Z"
          fill="#7F3E3B"
        />
        <path
          d="M593.263 326.199C593.141 327.255 592.483 328.066 591.733 327.989C590.984 327.913 590.571 327.01 590.693 325.924C590.816 324.838 591.458 324.073 592.146 324.134C592.835 324.196 593.37 325.129 593.263 326.199Z"
          fill="#2E353A"
        />
        <path
          d="M581.27 324.945C581.163 326.001 580.505 326.796 579.817 326.735C579.129 326.674 578.639 325.741 578.746 324.67C578.853 323.599 579.511 322.804 580.276 322.88C581.041 322.957 581.27 323.859 581.27 324.945Z"
          fill="#2E353A"
        />
        <path
          d="M580.429 322.972L577.951 321.962C577.951 321.962 579.053 324.089 580.429 322.972Z"
          fill="#2E353A"
        />
        <path
          d="M592.497 324.287L590.004 323.293C590.004 323.293 591.121 325.358 592.497 324.287Z"
          fill="#2E353A"
        />
        <path
          d="M608.085 345.565C608.886 345.567 609.671 345.335 610.343 344.898C611.015 344.462 611.546 343.839 611.87 343.106C612.194 342.373 612.298 341.562 612.169 340.77C612.04 339.979 611.684 339.243 611.144 338.651L610.608 339.14C611.184 339.765 611.502 340.585 611.496 341.435C611.499 342.111 611.301 342.772 610.927 343.336C610.553 343.899 610.021 344.339 609.397 344.599C608.773 344.859 608.086 344.928 607.423 344.796C606.76 344.665 606.151 344.34 605.673 343.862C605.195 343.384 604.869 342.775 604.738 342.112C604.607 341.449 604.676 340.762 604.936 340.138C605.196 339.514 605.636 338.981 606.199 338.608C606.762 338.234 607.424 338.036 608.1 338.039C608.579 338.035 609.054 338.134 609.492 338.33L609.782 337.672C609.255 337.428 608.681 337.302 608.1 337.305C607.557 337.305 607.02 337.412 606.519 337.619C606.018 337.827 605.563 338.131 605.179 338.514C604.796 338.898 604.492 339.353 604.284 339.854C604.077 340.355 603.97 340.892 603.97 341.435C603.97 341.977 604.077 342.514 604.284 343.015C604.492 343.516 604.796 343.972 605.179 344.355C605.563 344.739 606.018 345.043 606.519 345.251C607.02 345.458 607.557 345.565 608.1 345.565H608.085Z"
          fill="#407BFF"
        />
        <g opacity="0.6">
          <path
            d="M608.085 345.565C608.887 345.567 609.672 345.335 610.344 344.898C611.016 344.462 611.547 343.839 611.871 343.106C612.195 342.373 612.299 341.562 612.17 340.77C612.041 339.979 611.685 339.243 611.145 338.651L610.609 339.14C611.185 339.765 611.503 340.585 611.497 341.435C611.5 342.111 611.302 342.772 610.928 343.336C610.554 343.899 610.022 344.339 609.398 344.599C608.774 344.859 608.087 344.928 607.424 344.796C606.761 344.665 606.151 344.34 605.674 343.862C605.196 343.384 604.87 342.775 604.739 342.112C604.608 341.449 604.677 340.762 604.937 340.138C605.197 339.514 605.637 338.981 606.2 338.608C606.763 338.234 607.425 338.036 608.101 338.039C608.58 338.035 609.055 338.134 609.493 338.33L609.783 337.672C609.256 337.428 608.682 337.302 608.101 337.305C607.558 337.305 607.021 337.412 606.52 337.619C606.019 337.827 605.564 338.131 605.18 338.514C604.797 338.898 604.493 339.353 604.285 339.854C604.078 340.355 603.971 340.892 603.971 341.435C603.971 341.977 604.078 342.514 604.285 343.015C604.493 343.516 604.797 343.972 605.18 344.355C605.564 344.739 606.019 345.043 606.52 345.251C607.021 345.458 607.558 345.565 608.101 345.565H608.085Z"
            fill="white"
          />
        </g>
        <path
          d="M605.239 408.036L594.011 408.526L590.248 402.407C590.007 402.013 589.679 401.679 589.289 401.43C588.9 401.181 588.459 401.023 588 400.969L587.266 400.877C586.661 400.799 586.047 400.902 585.502 401.175C584.957 401.447 584.506 401.876 584.206 402.407L581.422 407.287C581.137 407.781 580.996 408.345 581.015 408.916C581.034 409.486 581.212 410.04 581.529 410.514L582.83 412.487C583.141 412.967 583.561 413.366 584.055 413.653C584.549 413.94 585.104 414.107 585.675 414.139L591.411 414.461L604.107 416.174L605.239 408.036Z"
          fill="#7F3E3B"
        />
        <path
          d="M625.202 367.118L630.785 407.149H598.219L597.438 416.618C597.438 416.618 642.181 425.291 644.307 417.566C646.433 409.841 647.214 385.26 644.001 375.393C642.365 370.422 637.561 364.686 628.322 363.844C627.889 363.806 627.453 363.865 627.047 364.019C626.64 364.172 626.273 364.415 625.973 364.729C625.673 365.044 625.448 365.422 625.315 365.835C625.181 366.249 625.143 366.687 625.202 367.118V367.118Z"
          fill="#407BFF"
        />
        <path
          d="M482.897 136.14H447.149L411.11 548.229H446.858C455.026 548.081 462.855 544.945 468.867 539.415C474.879 533.885 478.657 526.344 479.486 518.217L510.278 166.167C510.703 162.344 510.308 158.475 509.119 154.818C507.93 151.16 505.974 147.799 503.383 144.957C500.791 142.115 497.624 139.859 494.091 138.338C490.559 136.818 486.742 136.068 482.897 136.14Z"
          fill="#263238"
        />
        <path
          d="M505.047 177.44C505.124 176.672 505.476 175.958 506.038 175.43C506.6 174.901 507.335 174.594 508.106 174.565C508.475 174.558 508.84 174.63 509.179 174.776C509.517 174.921 509.821 175.137 510.07 175.409C510.319 175.681 510.507 176.003 510.622 176.353C510.737 176.703 510.776 177.074 510.737 177.44L508.32 204.974C508.283 205.776 507.946 206.535 507.377 207.101C506.808 207.667 506.048 208 505.246 208.033C504.878 208.038 504.514 207.964 504.178 207.817C503.841 207.671 503.539 207.455 503.292 207.183C503.045 206.911 502.858 206.59 502.744 206.241C502.63 205.892 502.591 205.523 502.63 205.158L505.047 177.44Z"
          fill="#407BFF"
        />
        <path
          opacity="0.3"
          d="M505.047 177.44C505.124 176.672 505.476 175.958 506.038 175.43C506.6 174.901 507.335 174.594 508.106 174.565C508.475 174.558 508.84 174.63 509.179 174.776C509.517 174.921 509.821 175.137 510.07 175.409C510.319 175.681 510.507 176.003 510.622 176.353C510.737 176.703 510.776 177.074 510.737 177.44L508.32 204.974C508.283 205.776 507.946 206.535 507.377 207.101C506.808 207.667 506.048 208 505.246 208.033C504.878 208.038 504.514 207.964 504.178 207.817C503.841 207.671 503.539 207.455 503.292 207.183C503.045 206.911 502.858 206.59 502.744 206.241C502.63 205.892 502.591 205.523 502.63 205.158L505.047 177.44Z"
          fill="white"
        />
        <path
          d="M439.532 548.229H292.241C288.398 548.298 284.584 547.547 281.054 546.027C277.524 544.506 274.358 542.251 271.768 539.412C269.177 536.572 267.221 533.213 266.03 529.559C264.839 525.904 264.44 522.038 264.86 518.217L295.668 166.167C296.487 158.035 300.261 150.486 306.274 144.952C312.288 139.418 320.124 136.282 328.295 136.14H475.586C479.428 136.071 483.24 136.822 486.769 138.341C490.298 139.861 493.463 142.114 496.053 144.952C498.643 147.79 500.6 151.147 501.792 154.799C502.984 158.452 503.384 162.317 502.967 166.136L472.159 518.217C471.331 526.344 467.553 533.885 461.541 539.415C455.529 544.945 447.699 548.081 439.532 548.229V548.229Z"
          fill="#407BFF"
        />
        <path
          d="M474.99 143.054H436.749C435.503 143.078 434.302 143.519 433.337 144.307C432.372 145.094 431.699 146.183 431.425 147.398L431.165 148.606C430.501 151.577 428.858 154.238 426.5 156.163C424.143 158.088 421.207 159.165 418.163 159.222H381.727C378.844 159.284 376.055 158.202 373.968 156.212C371.881 154.223 370.667 151.488 370.591 148.606V147.398C370.575 146.811 370.444 146.233 370.205 145.698C369.965 145.162 369.623 144.678 369.197 144.275C368.77 143.871 368.269 143.556 367.721 143.346C367.173 143.137 366.589 143.037 366.002 143.054H327.761C321.471 143.17 315.442 145.586 310.811 149.844C306.181 154.102 303.27 159.909 302.628 166.167L271.744 518.217C271.42 521.161 271.727 524.14 272.644 526.957C273.561 529.773 275.068 532.361 277.064 534.549C279.06 536.738 281.499 538.476 284.219 539.647C286.939 540.819 289.877 541.398 292.838 541.346H440.144C446.436 541.229 452.468 538.811 457.098 534.549C461.729 530.288 464.638 524.478 465.277 518.217L496.084 166.167C496.406 163.224 496.097 160.246 495.179 157.432C494.261 154.618 492.754 152.031 490.758 149.845C488.763 147.658 486.325 145.922 483.606 144.751C480.887 143.58 477.95 143.001 474.99 143.054Z"
          fill="#407BFF"
        />
        <path
          opacity="0.7"
          d="M474.99 143.054H436.749C435.503 143.078 434.302 143.519 433.337 144.307C432.372 145.094 431.699 146.183 431.425 147.398L431.165 148.606C430.501 151.577 428.858 154.238 426.5 156.163C424.143 158.088 421.207 159.165 418.163 159.222H381.727C378.844 159.284 376.055 158.202 373.968 156.212C371.881 154.223 370.667 151.488 370.591 148.606V147.398C370.575 146.811 370.444 146.233 370.205 145.698C369.965 145.162 369.623 144.678 369.197 144.275C368.77 143.871 368.269 143.556 367.721 143.346C367.173 143.137 366.589 143.037 366.002 143.054H327.761C321.471 143.17 315.442 145.586 310.811 149.844C306.181 154.102 303.27 159.909 302.628 166.167L271.744 518.217C271.42 521.161 271.727 524.14 272.644 526.957C273.561 529.773 275.068 532.361 277.064 534.549C279.06 536.738 281.499 538.476 284.219 539.647C286.939 540.819 289.877 541.398 292.838 541.346H440.144C446.436 541.229 452.468 538.811 457.098 534.549C461.729 530.288 464.638 524.478 465.277 518.217L496.084 166.167C496.406 163.224 496.097 160.246 495.179 157.432C494.261 154.618 492.754 152.031 490.758 149.845C488.763 147.658 486.325 145.922 483.606 144.751C480.887 143.58 477.95 143.001 474.99 143.054Z"
          fill="white"
        />
        <g opacity="0.2">
          <path
            d="M391.086 148.961C392.307 147.792 392.43 145.94 391.36 144.823C390.291 143.706 388.435 143.748 387.215 144.916C385.994 146.085 385.872 147.937 386.941 149.054C388.01 150.171 389.866 150.129 391.086 148.961Z"
            fill="black"
          />
          <path
            d="M413.084 144.033H403.05C402.254 144.047 401.491 144.352 400.905 144.89C400.319 145.429 399.95 146.163 399.868 146.954C399.829 147.327 399.869 147.703 399.987 148.059C400.104 148.415 400.296 148.741 400.549 149.018C400.802 149.294 401.11 149.513 401.455 149.661C401.799 149.809 402.17 149.882 402.545 149.876H412.579C413.374 149.858 414.135 149.552 414.721 149.014C415.306 148.477 415.676 147.744 415.761 146.954C415.803 146.581 415.764 146.204 415.647 145.847C415.531 145.49 415.34 145.162 415.086 144.885C414.833 144.609 414.523 144.389 414.177 144.242C413.832 144.095 413.459 144.024 413.084 144.033V144.033Z"
            fill="black"
          />
        </g>
        <path
          d="M319.193 159.176H322.283L322.559 156.071H319.453L319.193 159.176Z"
          fill="#407BFF"
        />
        <path
          d="M325.664 156.071L325.389 159.176H328.479L328.754 156.071L329.029 152.981H325.924L325.664 156.071Z"
          fill="#407BFF"
        />
        <path
          d="M332.395 149.876L332.119 152.981L331.844 156.071L331.584 159.176H334.674L334.949 156.071L335.225 152.981L335.485 149.876H332.395Z"
          fill="#407BFF"
        />
        <path
          d="M465.582 150.09L465.383 152.354H463.854L463.456 156.943H464.986L464.787 159.222H478.416L479.212 150.136L465.582 150.09ZM471.043 156.897H466.454L466.852 152.308H471.441L471.043 156.897Z"
          fill="#407BFF"
        />
        <g opacity="0.4">
          <path
            d="M381.833 159.222L280.156 537.308C283.847 539.966 288.289 541.381 292.837 541.346H300.791L403.554 159.222H381.833Z"
            fill="white"
          />
          <path
            d="M474.989 143.054H436.747C435.502 143.078 434.301 143.519 433.336 144.307C432.371 145.094 431.698 146.183 431.424 147.398L431.164 148.606C430.518 151.498 428.944 154.098 426.682 156.012C424.42 157.926 421.595 159.048 418.636 159.207L315.812 541.346H394.207L496.083 162.511C495.756 157.173 493.379 152.167 489.448 148.541C485.516 144.915 480.336 142.949 474.989 143.054V143.054Z"
            fill="white"
          />
        </g>
        <path
          d="M321.091 245.663L316.502 244.669C316.206 244.611 315.926 244.489 315.683 244.311C315.439 244.134 315.237 243.905 315.091 243.641C314.946 243.377 314.859 243.084 314.839 242.784C314.818 242.483 314.864 242.181 314.972 241.9L321.381 225.166L328.708 226.695L323.492 244.179C323.355 244.685 323.03 245.12 322.585 245.396C322.139 245.671 321.604 245.767 321.091 245.663Z"
          fill="#407BFF"
        />
        <path
          d="M338.865 224.248C338.563 224.183 338.277 224.058 338.023 223.882C337.77 223.706 337.553 223.481 337.387 223.221C337.221 222.961 337.108 222.67 337.054 222.366C337 222.062 337.008 221.75 337.075 221.449L337.687 218.573C337.752 218.269 337.877 217.982 338.054 217.727C338.231 217.472 338.458 217.255 338.72 217.089C338.981 216.922 339.274 216.809 339.58 216.757C339.886 216.704 340.199 216.713 340.502 216.783C341.112 216.915 341.645 217.284 341.984 217.809C342.322 218.334 342.438 218.972 342.307 219.582L341.679 222.443C341.544 223.054 341.172 223.588 340.644 223.926C340.117 224.264 339.477 224.38 338.865 224.248V224.248Z"
          fill="#407BFF"
        />
        <path
          d="M334.337 209.991C334.337 209.991 316.899 208.003 310.796 204.882C296.448 197.509 282.727 179.918 279.82 179.291C275.078 178.251 268.623 189.479 265.38 204.362C262.138 219.246 263.361 232.126 268.103 233.15C271.025 233.793 290.788 223.483 306.911 222.733C313.763 222.427 330.406 227.919 330.406 227.919L334.337 209.991Z"
          fill="#407BFF"
        />
        <path
          opacity="0.3"
          d="M334.337 209.991C334.337 209.991 316.899 208.003 310.796 204.882C296.448 197.509 282.727 179.918 279.82 179.291C275.078 178.251 268.623 189.479 265.38 204.362C262.138 219.246 263.361 232.126 268.103 233.15C271.025 233.793 290.788 223.483 306.911 222.733C313.763 222.427 330.406 227.919 330.406 227.919L334.337 209.991Z"
          fill="white"
        />
        <path
          d="M282.543 208.033C279.316 222.902 272.845 234.129 268.103 233.089C263.361 232.049 262.153 219.169 265.381 204.301C268.608 189.433 275.079 178.19 279.821 179.23C284.562 180.27 285.786 193.211 282.543 208.033Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M282.543 208.033C279.316 222.902 272.845 234.129 268.103 233.089C263.361 232.049 262.153 219.169 265.381 204.301C268.608 189.433 275.079 178.19 279.821 179.23C284.562 180.27 285.786 193.211 282.543 208.033Z"
          fill="black"
        />
        <path
          d="M331.508 208.033L323.416 206.274C322.895 206.159 322.356 206.153 321.833 206.255C321.309 206.358 320.812 206.567 320.373 206.87C319.934 207.173 319.562 207.563 319.28 208.015C318.998 208.468 318.813 208.974 318.735 209.502C318.444 211.536 318.077 213.632 317.618 215.773C317.16 217.915 316.609 219.98 316.089 221.892C315.94 222.406 315.899 222.944 315.968 223.474C316.036 224.005 316.213 224.515 316.487 224.974C316.761 225.433 317.126 225.831 317.56 226.143C317.994 226.455 318.488 226.674 319.01 226.787L328.601 228.867C334.353 230.122 338.498 226.068 339.661 220.286C340.181 217.582 339.618 214.783 338.092 212.491C336.567 210.199 334.202 208.598 331.508 208.033V208.033Z"
          fill="#263238"
        />
        <path
          d="M274.222 205.051L273.717 207.391C273.57 208.045 273.66 208.73 273.971 209.324C274.282 209.918 274.794 210.382 275.415 210.634L281.289 213.081C281.748 211.475 282.176 209.808 282.543 208.095C282.911 206.381 283.232 204.668 283.492 203.016L277.113 202.802C276.444 202.779 275.789 202.992 275.261 203.403C274.732 203.814 274.365 204.397 274.222 205.051V205.051Z"
          fill="#263238"
        />
        <path
          opacity="0.8"
          d="M274.222 205.051L273.717 207.391C273.57 208.045 273.66 208.73 273.971 209.324C274.282 209.918 274.794 210.382 275.415 210.634L281.289 213.081C281.748 211.475 282.176 209.808 282.543 208.095C282.911 206.381 283.232 204.668 283.492 203.016L277.113 202.802C276.444 202.779 275.789 202.992 275.261 203.403C274.732 203.814 274.365 204.397 274.222 205.051V205.051Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M278.658 207.238C278.337 208.768 277.985 210.144 277.603 211.552L281.289 213.081C281.748 211.475 282.176 209.808 282.543 208.095C282.91 206.381 283.232 204.668 283.492 203.016L279.484 202.878C279.26 204.276 278.984 205.729 278.658 207.238Z"
          fill="black"
        />
        <path
          d="M419.982 208.156C421.444 208.715 423.017 208.919 424.573 208.752C426.129 208.584 427.622 208.05 428.931 207.192C433.627 204.133 442.438 207.988 439.776 220.515C435.187 242.053 425.612 257.518 431.822 264.034C431.822 264.034 411.141 264.983 414.2 246.718C416.495 232.952 432.051 217.471 428.304 214.381C427.05 213.341 425.245 213.877 419.355 213.877L419.982 208.156Z"
          fill="#263238"
        />
        <path
          d="M419.355 213.877C422.19 213.783 425.022 214.124 427.753 214.886C428.717 212.113 429.092 209.17 428.855 206.244C425.842 206.59 422.87 207.23 419.983 208.156L419.355 213.877Z"
          fill="#407BFF"
        />
        <path
          d="M399.884 192.462C399.884 192.462 391.287 190.014 396.962 184.461C404.366 177.226 414.89 190.779 414.89 190.779C414.89 190.779 439.563 201.563 419.203 226.573C419.203 226.573 411.846 239.728 410.316 234.604C408.786 229.479 419.005 214.963 419.005 214.963L418.393 201.961L415.563 196.898L406.675 191.819L399.884 192.462Z"
          fill="#263238"
        />
        <path
          d="M365.787 265.717C365.204 264.763 364.439 263.934 363.535 263.276C362.631 262.618 361.607 262.145 360.52 261.884C359.433 261.622 358.305 261.578 357.201 261.752C356.097 261.927 355.038 262.318 354.085 262.902H353.963C351.286 264.539 348.441 266.359 345.626 268.042L337.137 273.136C333.16 275.471 329.162 277.74 325.144 279.943C324.915 276.889 324.864 273.824 324.991 270.765C325.098 267.185 325.358 263.529 325.634 259.873C325.909 256.218 326.276 252.47 326.582 248.845V248.753C326.66 247.734 326.367 246.721 325.759 245.899C325.151 245.078 324.267 244.503 323.269 244.281C322.272 244.058 321.227 244.202 320.327 244.686C319.427 245.17 318.732 245.962 318.368 246.917C317.006 250.604 315.936 254.244 314.865 257.977C313.794 261.709 312.983 265.472 312.265 269.357C311.48 273.391 311.05 277.486 310.98 281.595C310.919 284.18 311.254 286.759 311.974 289.243C312.63 291.464 313.903 293.453 315.645 294.979C316.966 296.095 318.542 296.868 320.234 297.228C321.709 297.514 323.226 297.514 324.701 297.228C325.055 297.149 325.403 297.041 325.741 296.907L326.246 296.708C329.504 295.362 332.747 294.015 335.898 292.486C339.049 290.956 342.2 289.426 345.275 287.82C348.349 286.214 351.393 284.486 354.452 282.696C357.512 280.906 360.357 279.117 363.37 277.036C365.122 275.798 366.343 273.946 366.791 271.849C367.238 269.751 366.88 267.562 365.787 265.717V265.717Z"
          fill="#CE7A63"
        />
        <path
          d="M373.237 254.55C378.392 264.937 370.973 273.946 357.374 288.386C350.272 282.627 345.311 274.649 343.286 265.732C343.286 265.732 359.011 252.761 373.237 254.55Z"
          fill="#407BFF"
        />
        <path
          opacity="0.2"
          d="M373.772 266.879C368.739 263.82 363.064 262.443 359.133 267.445C356.065 271.473 352.509 275.106 348.548 278.26C350.957 282.079 353.95 285.496 357.42 288.386C365.588 279.759 371.432 273.074 373.772 266.879Z"
          fill="black"
        />
        <path
          d="M322.758 229.449L326.246 231.758C326.896 232.182 327.435 232.756 327.816 233.433C328.197 234.109 328.408 234.868 328.433 235.644L328.708 243.001C328.754 244.449 328.456 245.887 327.839 247.198C327.222 248.509 326.304 249.655 325.16 250.543L323.706 251.659C317.588 248.218 313.626 240.049 313.626 240.049L312.219 237.846C311.917 237.403 311.706 236.903 311.599 236.377C311.492 235.852 311.49 235.31 311.594 234.783C311.698 234.257 311.905 233.756 312.204 233.31C312.503 232.865 312.888 232.483 313.335 232.187L317.649 229.342C318.42 228.864 319.313 228.62 320.219 228.639C321.126 228.658 322.008 228.939 322.758 229.449V229.449Z"
          fill="#CE7A63"
        />
        <path
          d="M361.291 541.346C369.214 516.871 374.629 489.689 369.245 469.85C401.979 373.695 387.218 345.703 387.218 345.703L348.258 331.492C348.258 331.492 342.69 418.086 341.007 459.983C340.074 483.402 336.082 513.858 331.478 541.361L361.291 541.346Z"
          fill="#263238"
        />
        <path
          opacity="0.5"
          d="M381.497 381.282L373.588 368.525L368.755 378.468C366.233 405.884 367.427 433.514 372.303 460.61C378.605 441.685 383.583 422.344 387.202 402.728L381.497 381.282Z"
          fill="black"
        />
        <path
          d="M385.229 541.346H414.766C417.826 515.908 417.826 488.542 408.801 470.247C422.568 369.611 402.682 344.968 402.682 344.968L361.718 338.483C361.718 338.483 372.838 424.541 379.217 465.949C382.506 487.395 384.372 515.219 385.229 541.346Z"
          fill="#263238"
        />
        <path
          d="M355.003 273.885C353.273 281.33 352.047 288.884 351.332 296.494C350.016 307.584 349.053 318.536 347.783 336.846L403.249 345.703C404.61 329.917 409.245 312.402 424.771 278.826C425.648 277.079 426.124 275.159 426.163 273.205C426.202 271.251 425.804 269.313 424.997 267.533C424.19 265.753 422.996 264.175 421.501 262.916C420.007 261.657 418.249 260.748 416.358 260.256C414.201 259.69 411.906 259.139 409.535 258.634C401.243 256.712 392.824 255.383 384.342 254.657C381.573 254.474 378.682 254.367 375.914 254.306C365.91 254.046 356.854 264.172 355.003 273.885Z"
          fill="#407BFF"
        />
        <path
          d="M412.243 229.923C408.77 237.678 403.738 252.103 408.143 258.344C408.143 258.344 400.648 255.973 382.766 263.468C379.11 255.958 382.965 254.29 382.965 254.29C392.495 253.602 393.826 246.933 393.673 240.523L412.243 229.923Z"
          fill="#CE7A63"
        />
        <path
          d="M386.514 261.984C387.475 262.338 388.35 262.894 389.078 263.614C389.806 264.334 390.372 265.203 390.736 266.16C391.04 266.857 391.488 267.481 392.051 267.991C392.613 268.502 393.278 268.888 394 269.123C394.723 269.359 395.487 269.438 396.242 269.358C396.998 269.277 397.728 269.037 398.384 268.654C402.973 266.069 408.755 262.382 411.646 259.109C410.148 257.969 408.713 256.748 407.348 255.453C407.348 255.453 390.139 259.384 386.514 261.984Z"
          fill="white"
        />
        <path
          d="M381.542 260.317C380.233 260.529 378.995 261.053 377.932 261.847C377.637 262.061 377.282 262.177 376.917 262.177C376.553 262.177 376.198 262.062 375.902 261.848C375.607 261.635 375.387 261.333 375.273 260.986C375.16 260.64 375.159 260.266 375.27 259.919C377.183 254.504 380.487 253.801 380.487 253.801C382.083 253.193 383.847 253.193 385.443 253.801C384.119 254.239 382.997 255.138 382.281 256.334C381.565 257.53 381.303 258.944 381.542 260.317Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M404.687 234.252L393.734 240.523C393.786 242.058 393.693 243.595 393.459 245.112C397.038 245.112 402.637 242.175 403.861 238.672C404.407 237.263 404.687 235.764 404.687 234.252V234.252Z"
          fill="black"
        />
        <path
          d="M421.742 214.764C417.153 227.46 415.623 232.906 407.103 237.999C394.392 245.648 379.753 238.229 380.38 224.661C380.961 212.424 388.273 193.731 402.437 191.544C405.464 190.972 408.589 191.22 411.488 192.263C414.386 193.305 416.953 195.104 418.923 197.473C420.892 199.842 422.192 202.694 422.687 205.734C423.183 208.775 422.857 211.892 421.742 214.764V214.764Z"
          fill="#CE7A63"
        />
        <path
          d="M404.686 214.29C404.35 215.376 403.524 216.125 402.82 215.957C402.116 215.789 401.826 214.779 402.147 213.693C402.468 212.607 403.325 211.873 404.028 212.041C404.732 212.209 405.023 213.219 404.686 214.29Z"
          fill="#263238"
        />
        <path
          d="M391.149 210.68C390.812 211.75 389.971 212.5 389.267 212.332C388.564 212.163 388.273 211.154 388.61 210.083C388.946 209.012 389.787 208.248 390.491 208.416C391.195 208.584 391.485 209.563 391.149 210.68Z"
          fill="#263238"
        />
        <path
          d="M390.781 208.554L388.426 207.192C388.426 207.192 389.129 209.563 390.781 208.554Z"
          fill="#263238"
        />
        <path
          d="M395.08 212.913C393.356 215.159 391.351 217.174 389.114 218.909C389.557 219.432 390.111 219.849 390.735 220.129C391.36 220.41 392.04 220.547 392.724 220.531L395.08 212.913Z"
          fill="#BA4D3C"
        />
        <path
          d="M408.801 208.033C408.714 208.026 408.631 207.998 408.556 207.953C408.482 207.908 408.419 207.847 408.372 207.773C407.955 207.088 407.372 206.519 406.677 206.118C405.982 205.717 405.197 205.498 404.395 205.479C404.312 205.481 404.229 205.466 404.151 205.436C404.073 205.405 404.002 205.359 403.942 205.301C403.882 205.242 403.835 205.172 403.802 205.095C403.77 205.018 403.753 204.935 403.753 204.852C403.764 204.673 403.84 204.504 403.967 204.377C404.093 204.25 404.262 204.174 404.441 204.163C405.455 204.181 406.448 204.45 407.332 204.947C408.216 205.444 408.962 206.152 409.504 207.009C409.564 207.122 409.591 207.249 409.583 207.377C409.575 207.504 409.533 207.627 409.461 207.733C409.388 207.838 409.289 207.922 409.173 207.975C409.056 208.028 408.928 208.048 408.801 208.033V208.033Z"
          fill="#263238"
        />
        <path
          d="M388.243 201.594C388.167 201.563 388.098 201.517 388.041 201.459C387.983 201.402 387.937 201.333 387.906 201.257C387.853 201.093 387.866 200.914 387.943 200.759C388.021 200.605 388.156 200.487 388.319 200.431C389.299 200.019 390.36 199.838 391.421 199.901C392.482 199.965 393.515 200.272 394.438 200.798C394.507 200.846 394.565 200.908 394.608 200.98C394.652 201.052 394.681 201.132 394.692 201.215C394.704 201.298 394.698 201.383 394.675 201.464C394.653 201.545 394.613 201.62 394.56 201.685C394.454 201.825 394.299 201.919 394.126 201.95C393.954 201.981 393.776 201.947 393.627 201.854C392.89 201.441 392.067 201.205 391.224 201.162C390.38 201.12 389.538 201.273 388.763 201.609C388.68 201.641 388.591 201.656 388.501 201.653C388.412 201.651 388.324 201.63 388.243 201.594V201.594Z"
          fill="#263238"
        />
        <path
          d="M404.319 212.179L401.979 210.817C401.979 210.817 402.667 213.158 404.319 212.179Z"
          fill="#263238"
        />
        <path
          d="M353.871 315.584H340.655C340.013 315.555 339.397 315.319 338.901 314.911C338.404 314.503 338.053 313.944 337.902 313.32L331.783 284.685C331.705 284.415 331.692 284.132 331.745 283.857C331.799 283.582 331.917 283.323 332.091 283.103C332.265 282.883 332.488 282.708 332.743 282.592C332.998 282.476 333.277 282.422 333.557 282.436H346.774C347.414 282.463 348.028 282.697 348.524 283.102C349.021 283.508 349.372 284.063 349.527 284.685L355.646 313.32C355.723 313.59 355.736 313.874 355.682 314.15C355.629 314.425 355.51 314.684 355.337 314.905C355.164 315.126 354.941 315.303 354.686 315.421C354.431 315.539 354.152 315.595 353.871 315.584V315.584Z"
          fill="#263238"
        />
        <path
          d="M352.342 315.584H339.126C338.483 315.555 337.868 315.319 337.371 314.911C336.875 314.503 336.524 313.944 336.372 313.32L330.254 284.685C330.175 284.415 330.162 284.132 330.216 283.857C330.27 283.582 330.388 283.323 330.562 283.103C330.735 282.883 330.959 282.708 331.214 282.592C331.469 282.476 331.748 282.422 332.028 282.436H345.244C345.885 282.463 346.499 282.697 346.995 283.102C347.491 283.508 347.843 284.063 347.998 284.685L354.116 313.32C354.194 313.59 354.207 313.874 354.153 314.15C354.099 314.425 353.981 314.684 353.808 314.905C353.635 315.126 353.411 315.303 353.156 315.421C352.902 315.539 352.623 315.595 352.342 315.584V315.584Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M352.342 315.584H339.126C338.483 315.555 337.868 315.319 337.371 314.911C336.875 314.503 336.524 313.944 336.372 313.32L330.254 284.685C330.175 284.415 330.162 284.132 330.216 283.857C330.27 283.582 330.388 283.323 330.562 283.103C330.735 282.883 330.959 282.708 331.214 282.592C331.469 282.476 331.748 282.422 332.028 282.436H345.244C345.885 282.463 346.499 282.697 346.995 283.102C347.491 283.508 347.843 284.063 347.998 284.685L354.116 313.32C354.194 313.59 354.207 313.874 354.153 314.15C354.099 314.425 353.981 314.684 353.808 314.905C353.635 315.126 353.411 315.303 353.156 315.421C352.902 315.539 352.623 315.595 352.342 315.584V315.584Z"
          fill="white"
        />
        <path
          d="M347.599 298.712L351.699 297.809C352.456 297.641 353.242 297.657 353.993 297.853C354.743 298.05 355.435 298.423 356.013 298.941L361.489 303.866C362.562 304.841 363.389 306.056 363.902 307.412C364.415 308.768 364.6 310.227 364.441 311.668L364.227 313.473C357.542 315.446 348.93 312.662 348.93 312.662L346.36 312.142C345.831 312.05 345.326 311.854 344.874 311.565C344.421 311.276 344.031 310.9 343.725 310.459C343.419 310.017 343.203 309.52 343.091 308.995C342.979 308.47 342.973 307.928 343.072 307.4L344.035 302.306C344.224 301.429 344.66 300.625 345.292 299.988C345.923 299.35 346.724 298.908 347.599 298.712V298.712Z"
          fill="#CE7A63"
        />
        <path
          d="M418.101 268.929C415.858 268.716 413.622 269.402 411.884 270.836C410.146 272.27 409.048 274.335 408.831 276.577V276.868C408.678 278.398 408.48 280.264 408.281 281.992C408.082 283.721 407.837 285.511 407.577 287.27C407.057 290.803 406.43 294.291 405.726 297.748C405.023 301.205 404.197 304.601 403.202 307.92C402.392 310.582 401.458 313.151 400.464 315.66C399.231 315.71 397.995 315.633 396.778 315.431C392.828 314.837 388.923 313.973 385.091 312.846C381.496 311.714 377.871 310.398 374.246 309.021C370.621 307.645 366.965 306.161 363.401 304.738H363.309C362.35 304.34 361.278 304.307 360.297 304.646C359.315 304.985 358.493 305.673 357.985 306.579C357.478 307.485 357.32 308.546 357.543 309.56C357.766 310.574 358.353 311.471 359.194 312.081C362.559 314.559 365.955 316.67 369.458 318.872C372.961 321.075 376.586 322.957 380.365 324.777C384.714 326.683 389.217 328.218 393.825 329.366C396.318 330.053 398.888 330.424 401.474 330.467C403.206 330.487 404.93 330.234 406.583 329.718C408.901 329.008 410.969 327.656 412.549 325.817C412.823 325.476 413.068 325.113 413.283 324.731L413.436 324.44C415.414 320.798 417.131 317.021 418.575 313.136C419.969 309.349 421.153 305.489 422.124 301.572C423.118 297.702 423.883 293.801 424.51 289.885C424.801 287.912 425.061 285.954 425.291 283.981C425.52 282.008 425.688 280.096 425.795 277.862C425.912 275.669 425.177 273.515 423.743 271.85C422.309 270.185 420.288 269.139 418.101 268.929V268.929Z"
          fill="#CE7A63"
        />
        <path
          d="M420.656 262.168C430.11 268.853 430.568 278.857 425.459 298.008C418.622 301.434 402.01 297.105 402.01 297.105C402.01 297.105 407.241 267.369 420.656 262.168Z"
          fill="#407BFF"
        />
        <path
          d="M386.637 224.202C386.637 224.202 391.226 223.299 403.692 223.682C402.163 227.414 399.287 230.382 392.847 230.183C391.302 230.284 389.779 229.767 388.615 228.745C387.45 227.724 386.739 226.283 386.637 224.737C386.637 224.554 386.621 224.385 386.637 224.202Z"
          fill="#263238"
        />
        <path
          d="M395.906 227.261C393.437 227.052 390.964 227.624 388.839 228.898C390.013 229.706 391.406 230.138 392.831 230.137C395.296 230.354 397.766 229.78 399.883 228.5C398.722 227.677 397.329 227.243 395.906 227.261V227.261Z"
          fill="#BA4D3C"
        />
        <path
          d="M387.202 224.125C387.316 224.588 387.555 225.011 387.892 225.348C388.229 225.685 388.652 225.923 389.114 226.038C392.837 226.143 396.562 225.933 400.25 225.41C400.838 225.372 401.407 225.19 401.908 224.88C402.41 224.571 402.828 224.144 403.126 223.636C397.813 223.378 392.489 223.542 387.202 224.125V224.125Z"
          fill="white"
        />
        <path
          d="M402.545 190.702C401.743 190.825 400.951 191.004 400.174 191.238V191.972C402.186 191.945 404.19 192.239 406.109 192.844C416.694 196.071 418.346 202.022 414.797 210.817C413.099 215.009 412.182 223.896 414.14 225.563C414.644 225.992 416.174 225.93 418.163 225.563C419.478 222.504 420.732 218.94 422.339 214.443C423.469 211.502 423.793 208.312 423.278 205.204C422.764 202.096 421.429 199.182 419.411 196.762C417.394 194.342 414.767 192.505 411.802 191.439C408.837 190.374 405.641 190.12 402.545 190.702V190.702Z"
          fill="#263238"
        />
        <path
          d="M422.95 226.389C421.97 227.414 420.796 228.234 419.496 228.801C418.196 229.368 416.797 229.672 415.379 229.693C411.707 229.693 411.126 225.9 413.191 222.749C415.042 219.919 419.172 216.508 422.369 218.053C425.566 219.598 425.49 223.774 422.95 226.389Z"
          fill="#CE7A63"
        />
        <g opacity="0.9">
          <path
            d="M400.388 291.369C374.628 289.839 363.003 285.021 358.368 282.329C358.219 282.25 358.08 282.152 357.955 282.038C356.624 280.876 358.307 278.749 359.852 279.637C363.906 281.977 375.072 286.734 400.571 288.31C400.975 288.334 401.352 288.516 401.621 288.817C401.891 289.117 402.03 289.513 402.009 289.916V289.916C402 290.118 401.95 290.317 401.863 290.5C401.776 290.682 401.653 290.846 401.502 290.981C401.351 291.117 401.175 291.221 400.984 291.287C400.793 291.354 400.59 291.382 400.388 291.369Z"
            fill="white"
          />
        </g>
        <g opacity="0.9">
          <path
            d="M399.043 305.932C399.043 306.139 399.001 306.344 398.919 306.535C398.838 306.726 398.718 306.898 398.568 307.041C398.418 307.184 398.24 307.295 398.045 307.367C397.851 307.44 397.644 307.472 397.437 307.461C382.739 306.927 368.286 303.539 354.881 297.488C354.696 297.396 354.532 297.267 354.398 297.11C354.264 296.953 354.163 296.77 354.101 296.573C354.04 296.376 354.019 296.168 354.04 295.962C354.061 295.757 354.124 295.558 354.224 295.377V295.377C354.412 295.034 354.725 294.776 355.098 294.656C355.47 294.537 355.875 294.565 356.228 294.734C369.271 300.568 383.311 303.85 397.589 304.402C397.982 304.421 398.351 304.591 398.622 304.876C398.893 305.161 399.043 305.539 399.043 305.932V305.932Z"
            fill="white"
          />
        </g>
        <g opacity="0.9">
          <path
            d="M373.129 331.431C361.198 329.106 355.064 327.515 352.479 326.842C352.281 326.785 352.096 326.688 351.936 326.557C351.776 326.426 351.644 326.265 351.548 326.082C351.453 325.899 351.395 325.698 351.379 325.493C351.363 325.287 351.388 325.08 351.454 324.884C351.58 324.513 351.844 324.204 352.192 324.022C352.539 323.84 352.943 323.798 353.32 323.905C355.798 324.609 361.917 326.184 373.711 328.494C374.108 328.572 374.458 328.804 374.685 329.139C374.911 329.475 374.995 329.886 374.919 330.284V330.284C374.828 330.67 374.59 331.005 374.256 331.219C373.922 331.433 373.518 331.509 373.129 331.431Z"
            fill="white"
          />
        </g>
        <path
          d="M400.357 195.796C399.843 196.014 399.264 196.022 398.744 195.819C398.224 195.616 397.804 195.218 397.573 194.71C397.365 194.193 397.295 193.63 397.37 193.078C397.445 192.525 397.663 192.002 398.002 191.559C398.687 190.673 399.58 189.969 400.602 189.509C399.736 190.537 399.132 191.76 398.843 193.073C398.873 194.267 399.241 196.179 400.357 195.796Z"
          fill="#263238"
        />
        <path
          d="M413.13 227.016C413.127 227.157 413.094 227.295 413.034 227.422C412.974 227.549 412.887 227.662 412.78 227.753C412.673 227.844 412.548 227.911 412.413 227.95C412.279 227.989 412.137 228 411.998 227.98C411.717 227.919 411.46 227.781 411.254 227.581C411.049 227.381 410.903 227.127 410.835 226.848C410.72 226.283 410.72 225.7 410.835 225.135C410.928 223.831 411.244 222.554 411.768 221.357C412.296 220.15 413.257 219.184 414.461 218.649C414.676 218.904 414.813 219.216 414.856 219.547C414.9 219.877 414.847 220.214 414.705 220.515C414.39 221.053 414.032 221.565 413.635 222.045C413.174 222.729 412.875 223.508 412.761 224.325C412.647 225.141 412.721 225.973 412.977 226.756C413.099 226.787 413.007 227.001 413.13 227.016Z"
          fill="#263238"
        />
        <path
          d="M240.783 324.043H131.871C127.855 324.043 124.003 325.638 121.163 328.478C118.323 331.318 116.728 335.17 116.728 339.186V421.971C116.728 425.988 118.323 429.839 121.163 432.679C124.003 435.519 127.855 437.115 131.871 437.115H214.151C214.95 443.596 218.09 449.562 222.981 453.89C227.872 458.217 234.176 460.607 240.706 460.61C241.303 460.61 241.899 460.61 242.496 460.519C238.244 454.92 234.771 446.752 232.492 437.115H240.829C244.845 437.115 248.697 435.519 251.537 432.679C254.377 429.839 255.972 425.988 255.972 421.971V339.186C255.972 337.194 255.579 335.221 254.815 333.38C254.051 331.54 252.932 329.869 251.521 328.462C250.11 327.055 248.435 325.941 246.592 325.182C244.749 324.424 242.775 324.037 240.783 324.043Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M240.783 324.043H131.871C127.855 324.043 124.003 325.638 121.163 328.478C118.323 331.318 116.728 335.17 116.728 339.186V421.971C116.728 425.988 118.323 429.839 121.163 432.679C124.003 435.519 127.855 437.115 131.871 437.115H214.151C214.95 443.596 218.09 449.562 222.981 453.89C227.872 458.217 234.176 460.607 240.706 460.61C241.303 460.61 241.899 460.61 242.496 460.519C238.244 454.92 234.771 446.752 232.492 437.115H240.829C244.845 437.115 248.697 435.519 251.537 432.679C254.377 429.839 255.972 425.988 255.972 421.971V339.186C255.972 337.194 255.579 335.221 254.815 333.38C254.051 331.54 252.932 329.869 251.521 328.462C250.11 327.055 248.435 325.941 246.592 325.182C244.749 324.424 242.775 324.037 240.783 324.043Z"
          fill="white"
        />
        <path
          d="M262.596 359.225L271.07 351.378L278.321 364.793C278.321 364.793 269.923 371.202 264.951 367.255L262.596 359.225Z"
          fill="#CE7A63"
        />
        <path
          d="M262.596 359.209L256.829 371.477L264.737 378.024L271.651 366.827L262.596 359.209Z"
          fill="#CE7A63"
        />
        <path
          d="M279.131 353.198L280.523 358.261C280.868 359.444 280.842 360.705 280.448 361.873C280.054 363.041 279.312 364.06 278.321 364.793L275.261 366.827L266.604 351.622L274.022 349.756C274.584 349.641 275.164 349.639 275.726 349.75C276.289 349.861 276.824 350.084 277.3 350.404C277.775 350.725 278.182 351.137 278.497 351.617C278.811 352.096 279.027 352.634 279.131 353.198V353.198Z"
          fill="#CE7A63"
        />
        <path
          d="M262.596 359.225C262.596 359.225 265.655 349.771 265.166 346.88C264.905 345.152 266.695 345.427 268.133 345.947C268.687 346.136 269.172 346.489 269.521 346.958C269.871 347.428 270.07 347.993 270.091 348.578C270.213 351.638 270.091 357.603 267.751 357.986C266 358.251 264.276 358.666 262.596 359.225V359.225Z"
          fill="#CE7A63"
        />
        <path
          d="M272.492 369.045C270.115 366.387 266.945 364.563 263.452 363.844L260.882 363.309C259.353 363.003 259.062 363.309 258.878 363.753L258.144 364.9L256.615 367.179C255.605 368.709 254.595 370.238 253.555 371.768C251.521 374.827 249.395 377.688 247.253 380.472C245.248 383.133 243.044 385.639 240.66 387.967L240.033 388.548C239.238 387.692 238.503 386.789 237.708 385.856C235.47 383.025 233.381 380.079 231.452 377.03C229.478 373.971 223.084 364.701 221.341 361.366C220.469 359.714 219.612 358.032 218.801 356.349L217.624 353.825C217.272 353.014 216.843 352.066 216.599 351.454L216.43 351.056C215.176 348.296 212.88 346.145 210.045 345.073C207.209 344 204.065 344.094 201.298 345.333C198.531 346.571 196.367 348.855 195.278 351.685C194.19 354.514 194.266 357.659 195.489 360.433C196.04 361.672 196.468 362.559 197.019 363.584L198.549 366.521C199.569 368.469 200.588 370.381 201.608 372.257C203.78 376.036 210.694 385.673 213.233 389.298C215.807 392.989 218.622 396.504 221.662 399.822C224.86 403.351 228.401 406.553 232.232 409.382L232.706 409.734C233.882 410.587 235.237 411.161 236.669 411.413C238.1 411.665 239.569 411.588 240.966 411.187C244.083 410.277 246.989 408.759 249.517 406.721C251.448 405.189 253.244 403.495 254.886 401.657C257.756 398.43 260.37 394.983 262.703 391.348C264.951 387.875 266.924 384.326 268.821 380.778C269.724 378.973 270.611 377.183 271.437 375.347L272.661 372.594L273.257 371.202C273.441 370.712 273.533 370.177 272.492 369.045Z"
          fill="#263238"
        />
        <path
          opacity="0.4"
          d="M215.804 364.181L207.39 370.131L204.974 375.883C205.387 376.648 205.784 377.412 206.213 378.177C208.369 381.956 210.802 385.627 213.28 389.252C215.853 392.943 218.669 396.459 221.708 399.776C223.238 401.306 224.767 402.835 226.297 404.365C222.167 394.652 215.804 364.181 215.804 364.181Z"
          fill="black"
        />
        <path
          d="M140.605 410.682C141.263 425.979 138.846 425.275 138.785 437.115H213.325C217.379 401 215.482 395.447 215.558 371.477C215.558 357.986 213.493 344.219 200.063 342.811C184.289 341.273 168.397 341.375 152.644 343.117C150.284 343.405 148.005 344.161 145.941 345.341C143.877 346.521 142.07 348.101 140.625 349.989C139.18 351.877 138.127 354.034 137.527 356.335C136.928 358.635 136.794 361.032 137.133 363.385C140.146 383.776 138.51 395.325 140.605 410.682Z"
          fill="#263238"
        />
        <path
          opacity="0.9"
          d="M193.256 342.246C183.325 341.596 173.363 341.565 163.428 342.154C163.642 345.213 165.967 352.861 182.9 359.485C182.9 359.485 186.679 351.286 189.86 354.972C189.86 354.972 192.415 350.261 197.891 358.934C197.891 358.934 206.059 350.184 193.256 342.246Z"
          fill="white"
        />
        <path
          opacity="0.4"
          d="M152.965 363.936C154.602 378.116 145.546 401.826 140.728 413.283C140.728 412.487 140.728 411.616 140.728 410.682C139.412 401.107 139.565 393 139.198 383.332L152.965 363.936Z"
          fill="black"
        />
        <path
          d="M170.74 315.109C170.991 324.411 169.195 333.654 165.478 342.184C165.34 342.509 165.306 342.868 165.38 343.213C165.454 343.558 165.632 343.872 165.891 344.112C172.133 349.493 180.137 352.39 188.377 352.25C198.397 352.372 197.815 349.19 195.444 345.718C195.099 345.224 194.65 344.812 194.129 344.509C187.276 340.441 187.031 334.995 188.959 331.706L170.74 315.109Z"
          fill="#CE7A63"
        />
        <path
          opacity="0.2"
          d="M170.755 315.109L188.958 331.706C188.35 332.751 187.998 333.925 187.932 335.133C187.865 336.34 188.086 337.545 188.576 338.651C184.735 338.771 180.991 337.432 178.098 334.903C173.401 330.911 171.811 326.199 170.633 319.607C170.725 318.169 170.755 316.639 170.755 315.109Z"
          fill="black"
        />
        <path
          d="M165.325 306.482C166.12 318.719 164.391 320.677 170.602 326.873C179.948 336.188 195.168 337.963 197.325 325.572C199.252 314.406 200.384 294.199 188.315 289.488C185.656 288.43 182.77 288.068 179.932 288.436C177.093 288.804 174.396 289.89 172.094 291.591C169.792 293.293 167.963 295.553 166.778 298.159C165.593 300.764 165.093 303.629 165.325 306.482V306.482Z"
          fill="#CE7A63"
        />
        <path
          d="M195.031 319.698C195.031 319.698 190.732 318.337 178.541 317.817C179.841 322.192 182.396 325.832 188.637 326.031C194.878 326.23 195.031 319.698 195.031 319.698Z"
          fill="#263238"
        />
        <path
          d="M185.853 322.467C188.308 322.428 190.695 323.269 192.584 324.838C191.455 325.683 190.058 326.091 188.652 325.985C186.193 326.035 183.8 325.187 181.922 323.599C183.052 322.759 184.449 322.357 185.853 322.467V322.467Z"
          fill="#FF5652"
        />
        <path
          d="M194.48 319.5C194.113 320.402 193.73 321.32 192.507 321.565C188.897 321.394 185.312 320.882 181.799 320.035C181.196 319.926 180.631 319.661 180.161 319.267C179.692 318.872 179.334 318.362 179.122 317.786C184.283 317.886 189.424 318.46 194.48 319.5V319.5Z"
          fill="white"
        />
        <path
          d="M190.243 308.272C191.532 310.553 193.133 312.644 195 314.482C194.518 314.954 193.938 315.314 193.301 315.536C192.663 315.758 191.985 315.837 191.314 315.767L190.243 308.272Z"
          fill="#BA4D3C"
        />
        <path
          d="M178.174 302.995C178.069 302.981 177.972 302.932 177.898 302.857C177.841 302.8 177.795 302.732 177.763 302.657C177.732 302.582 177.716 302.502 177.716 302.421C177.716 302.34 177.732 302.259 177.763 302.185C177.795 302.11 177.841 302.042 177.898 301.985C178.532 301.241 179.335 300.66 180.24 300.29C181.145 299.92 182.125 299.772 183.099 299.859C183.261 299.883 183.407 299.971 183.505 300.103C183.602 300.235 183.644 300.4 183.619 300.562C183.595 300.725 183.507 300.871 183.375 300.968C183.243 301.066 183.078 301.107 182.916 301.082V301.082C182.142 301.023 181.365 301.148 180.649 301.449C179.933 301.749 179.3 302.216 178.801 302.811C178.724 302.898 178.625 302.961 178.514 302.994C178.403 303.026 178.285 303.026 178.174 302.995V302.995Z"
          fill="#263238"
        />
        <path
          d="M198.488 305.167C198.388 305.184 198.285 305.176 198.189 305.144C198.093 305.112 198.007 305.057 197.937 304.983C197.43 304.405 196.792 303.956 196.076 303.674C195.36 303.392 194.588 303.285 193.822 303.362C193.664 303.389 193.502 303.352 193.371 303.261C193.239 303.169 193.149 303.03 193.119 302.872C193.092 302.71 193.13 302.544 193.224 302.41C193.319 302.275 193.462 302.183 193.624 302.153C194.588 302.047 195.565 302.172 196.472 302.518C197.379 302.864 198.19 303.42 198.84 304.142C198.897 304.199 198.943 304.267 198.975 304.341C199.006 304.416 199.022 304.497 199.022 304.578C199.022 304.659 199.006 304.739 198.975 304.814C198.943 304.889 198.897 304.957 198.84 305.014C198.741 305.099 198.618 305.153 198.488 305.167V305.167Z"
          fill="#263238"
        />
        <path
          d="M117.905 413.176C117.932 413.754 118.019 414.329 118.165 414.889C119.547 419.78 121.343 424.544 123.534 429.13C124.646 431.557 125.793 433.943 126.976 436.289C128.552 436.829 130.205 437.108 131.871 437.115H148.376L146.739 433.459C144.919 429.467 143.236 425.459 141.706 421.497C140.432 418.283 139.359 414.993 138.494 411.646C138.668 410.365 138.928 409.098 139.274 407.853C139.748 406.017 140.391 404.028 141.125 402.009C142.655 397.986 144.337 393.841 146.173 389.772C148.009 385.703 150.043 381.527 152.093 377.412C154.143 373.298 156.284 369.137 158.41 365.252V365.114L158.517 364.885C159.905 362.177 160.16 359.028 159.226 356.132C158.292 353.236 156.246 350.83 153.538 349.443C150.83 348.055 147.682 347.8 144.786 348.734C141.89 349.668 139.484 351.714 138.096 354.422C135.802 358.919 133.737 363.217 131.657 367.668C129.576 372.12 127.695 376.617 125.859 381.206C124.024 385.795 122.372 390.43 120.842 395.401C120.092 397.894 119.419 400.464 118.853 403.233C118.15 406.385 117.826 409.61 117.89 412.839L117.905 413.176Z"
          fill="#263238"
        />
        <path
          d="M159.772 314.681C160.833 317.304 162.85 319.425 165.416 320.616C168.69 322.023 170.495 318.826 169.715 315.155C169.026 311.867 166.472 307.155 163.122 307.308C159.772 307.461 158.38 311.316 159.772 314.681Z"
          fill="#CE7A63"
        />
        <path
          d="M181.064 307.155C181.064 308.211 181.508 309.113 182.227 309.159C182.946 309.205 183.542 308.379 183.588 307.293C183.634 306.207 183.145 305.335 182.441 305.289C181.737 305.243 181.126 306.069 181.064 307.155Z"
          fill="#2E353A"
        />
        <path
          d="M193.164 307.859C193.164 308.93 193.623 309.832 194.311 309.878C195 309.924 195.627 309.083 195.703 308.012C195.78 306.941 195.244 306.054 194.556 306.008C193.868 305.962 193.225 306.788 193.164 307.859Z"
          fill="#2E353A"
        />
        <path
          d="M228.164 109.478H119.314C115.3 109.482 111.452 111.079 108.616 113.918C105.779 116.758 104.186 120.608 104.186 124.621V207.406C104.184 209.394 104.573 211.363 105.333 213.2C106.092 215.038 107.206 216.707 108.611 218.114C110.016 219.52 111.685 220.636 113.521 221.397C115.357 222.158 117.326 222.55 119.314 222.55H201.564C202.352 229.034 205.487 235.005 210.376 239.337C215.265 243.668 221.571 246.06 228.103 246.061C228.715 246.061 229.312 246.061 229.908 245.969C225.656 240.355 222.168 232.202 219.904 222.55H228.241C232.257 222.55 236.109 220.954 238.949 218.114C241.789 215.274 243.384 211.423 243.384 207.406V124.621C243.382 122.627 242.987 120.652 242.22 118.811C241.454 116.969 240.331 115.297 238.917 113.89C237.504 112.484 235.826 111.37 233.981 110.612C232.135 109.855 230.159 109.47 228.164 109.478Z"
          fill="#407BFF"
        />
        <path
          opacity="0.4"
          d="M228.164 109.478H119.314C115.3 109.482 111.452 111.079 108.616 113.918C105.779 116.758 104.186 120.608 104.186 124.621V207.406C104.184 209.394 104.573 211.363 105.333 213.2C106.092 215.038 107.206 216.707 108.611 218.114C110.016 219.52 111.685 220.636 113.521 221.397C115.357 222.158 117.326 222.55 119.314 222.55H201.564C202.352 229.034 205.487 235.005 210.376 239.337C215.265 243.668 221.571 246.06 228.103 246.061C228.715 246.061 229.312 246.061 229.908 245.969C225.656 240.355 222.168 232.202 219.904 222.55H228.241C232.257 222.55 236.109 220.954 238.949 218.114C241.789 215.274 243.384 211.423 243.384 207.406V124.621C243.382 122.627 242.987 120.652 242.22 118.811C241.454 116.969 240.331 115.297 238.917 113.89C237.504 112.484 235.826 111.37 233.981 110.612C232.135 109.855 230.159 109.47 228.164 109.478Z"
          fill="white"
        />
        <path
          d="M201.487 222.55C200.863 213.658 200.863 204.734 201.487 195.842C202.435 180.178 197.754 163.245 194.221 160.354C189.127 156.147 184.385 152.186 182.703 157.478C181.724 160.538 181.433 165.585 181.494 171.245C181.647 184.92 184.553 201.976 185.93 209.854C186.435 212.638 187.705 222.565 187.705 222.565L201.487 222.55Z"
          fill="#407BFF"
        />
        <path
          d="M191.972 161.027C191.798 159.903 191.302 158.853 190.545 158.003C189.788 157.154 188.801 156.541 187.704 156.239C183.88 155.214 177.272 153.547 171.261 152.476C164.177 151.325 157.021 150.671 149.845 150.518C145.256 150.335 140.851 150.518 137.317 150.671C136.899 150.704 136.484 150.776 136.078 150.885C135.332 151.097 134.635 151.454 134.026 151.935C133.417 152.416 132.91 153.013 132.532 153.69C132.154 154.368 131.913 155.114 131.824 155.884C131.734 156.655 131.797 157.436 132.01 158.182C141.08 190.565 142.962 206.06 143.436 222.55H187.704C189.769 207.59 193.823 195.307 194.19 186.511C194.505 177.957 193.76 169.398 191.972 161.027Z"
          fill="#407BFF"
        />
        <path
          opacity="0.8"
          d="M191.972 161.027C191.798 159.903 191.302 158.853 190.545 158.003C189.788 157.154 188.801 156.541 187.704 156.239C183.88 155.214 177.272 153.547 171.261 152.476C164.177 151.325 157.021 150.671 149.845 150.518C145.256 150.335 140.851 150.518 137.317 150.671C136.899 150.704 136.484 150.776 136.078 150.885C135.332 151.097 134.635 151.454 134.026 151.935C133.417 152.416 132.91 153.013 132.532 153.69C132.154 154.368 131.913 155.114 131.824 155.884C131.734 156.655 131.797 157.436 132.01 158.182C141.08 190.565 142.962 206.06 143.436 222.55H187.704C189.769 207.59 193.823 195.307 194.19 186.511C194.505 177.957 193.76 169.398 191.972 161.027Z"
          fill="white"
        />
        <path
          d="M173.785 144.996C170.879 148.927 168.753 153.593 173.311 158.763C174.3 159.842 174.879 161.233 174.948 162.694C174.948 165.035 173.648 167.283 166.03 162.817C154.986 156.331 155.919 149.05 155.919 149.05C161.716 147.291 165.189 135.099 166.535 128.124L173.785 144.996Z"
          fill="#CE7A63"
        />
        <path
          opacity="0.2"
          d="M167.146 138.541C166.304 141.509 169.44 146.189 171.826 148.117C172.382 147.042 173.032 146.018 173.769 145.057L169.486 135.038C168.484 136.042 167.689 137.232 167.146 138.541V138.541Z"
          fill="black"
        />
        <path
          d="M196.21 108.315C200.096 113.241 191.346 128.078 190.398 124.973C188.868 119.925 186.635 109.676 186.665 105.47C186.727 98.8312 192.34 103.405 196.21 108.315Z"
          fill="#263238"
        />
        <path
          d="M163.995 109.86C165.026 108.105 166.393 106.57 168.018 105.344C169.642 104.117 171.492 103.222 173.463 102.711C175.433 102.2 177.485 102.081 179.5 102.363C181.516 102.645 183.457 103.321 185.212 104.353C195.629 110.38 194.833 127.298 191.009 137.027C186.818 147.872 172.653 149.264 165.326 139.398C160.401 132.82 161.028 128.032 161.9 116.698C162.052 114.286 162.77 111.943 163.995 109.86V109.86Z"
          fill="#CE7A63"
        />
        <path
          d="M177.838 122.373C177.762 123.306 178.175 124.101 178.771 124.162C179.368 124.223 179.934 123.52 180.041 122.633C180.148 121.745 179.704 120.904 179.108 120.843C178.511 120.782 177.976 121.562 177.838 122.373Z"
          fill="#263238"
        />
        <path
          d="M178.802 120.981L181.081 120.568C181.081 120.568 179.796 122.128 178.802 120.981Z"
          fill="#263238"
        />
        <path
          d="M189.434 125.019C189.434 125.952 189.77 126.747 190.367 126.809C190.963 126.87 191.529 126.166 191.636 125.279C191.743 124.392 191.3 123.55 190.703 123.489C190.107 123.428 189.571 124.147 189.434 125.019Z"
          fill="#263238"
        />
        <path
          d="M190.396 123.566L192.676 123.153C192.676 123.153 191.391 124.713 190.396 123.566Z"
          fill="#263238"
        />
        <path
          d="M185.089 132.009C185.625 132.227 186.202 132.327 186.781 132.3C187.359 132.274 187.925 132.122 188.439 131.857C187.338 129.878 186.515 127.757 185.991 125.554L185.089 132.009Z"
          fill="#BA4D3C"
        />
        <path
          d="M176.355 131.627C176.327 131.562 176.323 131.488 176.346 131.42C176.369 131.352 176.415 131.295 176.477 131.26C176.542 131.228 176.617 131.223 176.686 131.246C176.755 131.269 176.811 131.318 176.844 131.382C177.409 132.386 178.204 133.24 179.165 133.876C180.125 134.512 181.222 134.91 182.366 135.038C182.432 135.056 182.49 135.096 182.529 135.152C182.568 135.208 182.586 135.276 182.58 135.344V135.344C182.577 135.378 182.566 135.411 182.549 135.44C182.532 135.469 182.51 135.495 182.482 135.515C182.455 135.535 182.424 135.55 182.391 135.557C182.357 135.564 182.323 135.565 182.29 135.558C181.85 135.51 181.415 135.423 180.99 135.298C180.012 135.025 179.099 134.559 178.303 133.929C177.507 133.298 176.845 132.516 176.355 131.627V131.627Z"
          fill="#263238"
        />
        <path
          d="M177.441 116.483C177.356 116.37 177.319 116.227 177.339 116.086C177.359 115.946 177.435 115.819 177.548 115.734C178.457 115.183 179.473 114.833 180.528 114.706C181.583 114.579 182.653 114.679 183.667 115C183.801 115.048 183.912 115.147 183.974 115.276C184.037 115.404 184.048 115.552 184.003 115.688C183.958 115.821 183.862 115.931 183.736 115.994C183.61 116.057 183.464 116.068 183.33 116.025C182.485 115.752 181.592 115.66 180.71 115.755C179.827 115.85 178.974 116.129 178.206 116.575C178.117 116.646 178.006 116.684 177.892 116.684C177.778 116.684 177.668 116.646 177.579 116.575L177.441 116.483Z"
          fill="#263238"
        />
        <path
          d="M194.129 120.017C193.762 119.442 193.261 118.964 192.67 118.623C192.079 118.283 191.415 118.089 190.733 118.059C190.662 118.061 190.59 118.049 190.524 118.023C190.457 117.997 190.396 117.958 190.345 117.908C190.293 117.858 190.253 117.798 190.225 117.732C190.197 117.666 190.183 117.595 190.183 117.524C190.183 117.38 190.238 117.242 190.338 117.14C190.438 117.037 190.575 116.977 190.718 116.973C191.578 117.003 192.417 117.238 193.168 117.659C193.918 118.079 194.557 118.673 195.032 119.39V119.39C195.071 119.448 195.099 119.513 195.112 119.581C195.126 119.65 195.126 119.721 195.112 119.789C195.097 119.858 195.069 119.923 195.029 119.981C194.989 120.038 194.938 120.087 194.879 120.124C194.799 120.176 194.706 120.204 194.611 120.204C194.516 120.204 194.423 120.176 194.343 120.124L194.129 120.017Z"
          fill="#263238"
        />
        <path
          d="M180.21 107.596C178.501 107.917 176.885 108.613 175.478 109.634C174.07 110.655 172.907 111.976 172.072 113.501C171.292 114.938 166.275 118.09 165.418 119.512C162.726 123.994 165.663 136.751 164.393 137.715C163.124 138.679 163.567 134.901 162.359 134.166C161.077 133.588 159.848 132.896 158.688 132.101C157.158 130.816 151.85 116.973 158.397 108.59C161.655 104.169 166.424 101.099 171.797 99.9631C177.241 99.038 182.83 100.309 187.338 103.497C188.761 104.522 191.254 107.321 187.338 108.575C186.267 108.912 186.222 108.055 185.105 107.78C183.506 107.366 181.836 107.304 180.21 107.596V107.596Z"
          fill="#263238"
        />
        <path
          d="M154.175 119.512C157.953 115.841 162.665 115.841 166.825 115.841C168.802 115.998 170.791 115.774 172.684 115.183C176.875 113.363 179.445 107.382 179.919 105.424C180.173 104.623 180.626 103.899 181.236 103.321C181.846 102.743 182.593 102.33 183.407 102.12C184.069 102.087 184.73 102.209 185.338 102.474C185.945 102.739 186.483 103.142 186.91 103.65C187.659 104.736 187.583 106.311 186.68 108.239L187.277 108.499C188.271 106.281 188.317 104.506 187.445 103.221C186.952 102.626 186.329 102.152 185.623 101.836C184.918 101.52 184.149 101.372 183.376 101.401C182.42 101.614 181.538 102.078 180.82 102.745C180.103 103.411 179.575 104.257 179.292 105.195C178.864 107 176.34 112.843 172.424 114.525C170.613 115.082 168.712 115.284 166.825 115.122C162.756 115.122 157.647 115.122 153.731 118.977L154.175 119.512Z"
          fill="#263238"
        />
        <path
          opacity="0.1"
          d="M154.175 119.512C157.953 115.841 162.665 115.841 166.825 115.841C168.802 115.998 170.791 115.774 172.684 115.183C176.875 113.363 179.445 107.382 179.919 105.424C180.173 104.623 180.626 103.899 181.236 103.321C181.846 102.743 182.593 102.33 183.407 102.12C184.069 102.087 184.73 102.209 185.338 102.474C185.945 102.739 186.483 103.142 186.91 103.65C187.659 104.736 187.583 106.311 186.68 108.239L187.277 108.499C188.271 106.281 188.317 104.506 187.445 103.221C186.952 102.626 186.329 102.152 185.623 101.836C184.918 101.52 184.149 101.372 183.376 101.401C182.42 101.614 181.538 102.078 180.82 102.745C180.103 103.411 179.575 104.257 179.292 105.195C178.864 107 176.34 112.843 172.424 114.525C170.613 115.082 168.712 115.284 166.825 115.122C162.756 115.122 157.647 115.122 153.731 118.977L154.175 119.512Z"
          fill="white"
        />
        <path
          d="M157.417 123.811C157.48 123.325 157.637 122.856 157.881 122.432C158.125 122.007 158.45 121.635 158.838 121.337C159.226 121.038 159.669 120.819 160.142 120.693C160.615 120.566 161.108 120.534 161.593 120.598C164.408 121.027 165.693 124.82 165.693 127.298C165.693 130.036 163.658 132.071 161.18 130.526C159.324 129.342 158.01 127.473 157.525 125.325C157.382 124.833 157.346 124.317 157.417 123.811V123.811Z"
          fill="#CE7A63"
        />
        <path
          d="M162.419 164.178C161.823 172.01 154.587 201.594 138.908 202.42C136.41 202.489 133.977 201.622 132.086 199.987V199.987C119.451 192.339 129.379 155.474 131.918 153.333C133.02 152.387 134.342 151.733 135.763 151.431C137.184 151.128 138.657 151.186 140.05 151.6C141.442 152.014 142.708 152.77 143.733 153.8C144.757 154.829 145.507 156.099 145.914 157.494C146.244 156.327 146.505 155.142 146.694 153.945C147.352 149.952 149.524 140.652 153.532 139.979C155.949 139.566 160.92 139.352 162.358 141.325C163.796 143.298 163.414 151.253 162.419 164.178Z"
          fill="#407BFF"
        />
        <g opacity="0.2">
          <path
            d="M161.594 120.598V121.287L162.313 128.078V136.828L163.506 131.627C163.809 130.272 163.876 128.875 163.705 127.497L162.863 120.981L161.594 120.598Z"
            fill="black"
          />
        </g>
        <path
          d="M153.731 141.555L151.85 127.925L163.215 127.834C163.215 127.834 162.282 138.067 159.437 141.6L153.731 141.555Z"
          fill="#CE7A63"
        />
        <path
          d="M153.853 118.61L162.267 120.093L163.169 127.803L151.85 127.925L153.853 118.61Z"
          fill="#CE7A63"
        />
        <path
          opacity="0.2"
          d="M142.427 169.899C142.427 169.899 135.62 185.624 135.406 179.398C135.192 172.622 142.427 169.899 142.427 169.899Z"
          fill="black"
        />
        <path
          d="M561.981 261.847H519.885C517.958 261.847 516.11 262.612 514.747 263.975C513.385 265.338 512.619 267.186 512.619 269.113V296.218C512.623 298.143 513.39 299.987 514.753 301.346C516.115 302.705 517.961 303.469 519.885 303.469H531.985L537.384 310.536C537.8 311.081 538.335 311.523 538.95 311.827C539.564 312.132 540.24 312.29 540.925 312.29C541.611 312.29 542.287 312.132 542.901 311.827C543.516 311.523 544.051 311.081 544.467 310.536L549.866 303.469H561.981C563.904 303.469 565.748 302.705 567.108 301.345C568.468 299.985 569.232 298.141 569.232 296.218V269.113C569.232 267.188 568.468 265.343 567.109 263.98C565.75 262.618 563.906 261.851 561.981 261.847V261.847Z"
          fill="#407BFF"
        />
        <path
          opacity="0.7"
          d="M561.981 261.847H519.885C517.958 261.847 516.11 262.612 514.747 263.975C513.385 265.338 512.619 267.186 512.619 269.113V296.218C512.623 298.143 513.39 299.987 514.753 301.346C516.115 302.705 517.961 303.469 519.885 303.469H531.985L537.384 310.536C537.8 311.081 538.335 311.523 538.95 311.827C539.564 312.132 540.24 312.29 540.925 312.29C541.611 312.29 542.287 312.132 542.901 311.827C543.516 311.523 544.051 311.081 544.467 310.536L549.866 303.469H561.981C563.904 303.469 565.748 302.705 567.108 301.345C568.468 299.985 569.232 298.141 569.232 296.218V269.113C569.232 267.188 568.468 265.343 567.109 263.98C565.75 262.618 563.906 261.851 561.981 261.847V261.847Z"
          fill="white"
        />
        <path
          d="M559.534 280.478L546.088 272.279V277.205H539.97C536.01 277.205 532.213 278.777 529.413 281.577C526.613 284.377 525.04 288.174 525.04 292.134C525.045 293.24 525.168 294.343 525.407 295.423C526.159 292.121 528.008 289.171 530.653 287.057C533.299 284.942 536.583 283.788 539.97 283.782H546.088V288.723L559.534 280.478Z"
          fill="#407BFF"
        />
        <path
          d="M265.778 95.9401H298.054C299.531 95.9401 300.947 96.5267 301.991 97.5709C303.035 98.6151 303.622 100.031 303.622 101.508V122.373C303.622 123.103 303.478 123.826 303.198 124.501C302.918 125.176 302.507 125.789 301.99 126.304C301.473 126.82 300.859 127.229 300.184 127.507C299.508 127.785 298.785 127.927 298.054 127.925H288.769L284.639 133.356C284.321 133.774 283.91 134.113 283.439 134.347C282.968 134.58 282.45 134.702 281.924 134.702C281.398 134.702 280.88 134.58 280.409 134.347C279.938 134.113 279.527 133.774 279.209 133.356L275.063 127.925H265.778C265.049 127.927 264.326 127.785 263.651 127.507C262.976 127.228 262.363 126.819 261.847 126.303C261.331 125.787 260.923 125.174 260.644 124.5C260.366 123.825 260.224 123.102 260.226 122.373V101.508C260.224 100.778 260.366 100.054 260.644 99.3785C260.922 98.703 261.331 98.0891 261.847 97.5719C262.362 97.0546 262.975 96.6443 263.65 96.3642C264.325 96.0842 265.048 95.9401 265.778 95.9401V95.9401Z"
          fill="#407BFF"
        />
        <path
          opacity="0.7"
          d="M265.778 95.9401H298.054C299.531 95.9401 300.947 96.5267 301.991 97.5709C303.035 98.6151 303.622 100.031 303.622 101.508V122.373C303.622 123.103 303.478 123.826 303.198 124.501C302.918 125.176 302.507 125.789 301.99 126.304C301.473 126.82 300.859 127.229 300.184 127.507C299.508 127.785 298.785 127.927 298.054 127.925H288.769L284.639 133.356C284.321 133.774 283.91 134.113 283.439 134.347C282.968 134.58 282.45 134.702 281.924 134.702C281.398 134.702 280.88 134.58 280.409 134.347C279.938 134.113 279.527 133.774 279.209 133.356L275.063 127.925H265.778C265.049 127.927 264.326 127.785 263.651 127.507C262.976 127.228 262.363 126.819 261.847 126.303C261.331 125.787 260.923 125.174 260.644 124.5C260.366 123.825 260.224 123.102 260.226 122.373V101.508C260.224 100.778 260.366 100.054 260.644 99.3785C260.922 98.703 261.331 98.0891 261.847 97.5719C262.362 97.0546 262.975 96.6443 263.65 96.3642C264.325 96.0842 265.048 95.9401 265.778 95.9401V95.9401Z"
          fill="white"
        />
        <path
          d="M267.69 110.227L278.031 103.925V107.703H282.758C284.473 107.708 286.166 108.097 287.711 108.843C289.256 109.589 290.615 110.671 291.686 112.011C292.758 113.351 293.515 114.914 293.902 116.586C294.289 118.257 294.297 119.994 293.924 121.669C293.349 119.137 291.93 116.876 289.902 115.256C287.873 113.635 285.354 112.752 282.758 112.751H278.031V116.529L267.69 110.227Z"
          fill="#407BFF"
        />
      </svg>
    </Box>
  );
}
