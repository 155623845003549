import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { osName, getUA, browserName } from "react-device-detect";
// utils
import axios from "../utils/axios";
import { API_PATH } from "../utils/apis";
import { setSession, setHeader } from "../utils/jwt";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isVerifyEmail: false,
  isPopupOpen: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, isVerifyEmail, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isVerifyEmail,
      isInitialized: true,
      isPopupOpen: false,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user, isVerifyEmail } = action.payload;
    return {
      ...state,
      isVerifyEmail,
      isAuthenticated: true,
      isPopupOpen: false,
      user,
    };
  },
  LOGOUT: (state, action) => {
    const { isPopUp } = action.payload;
    return {
      ...state,
      isAuthenticated: false,
      isVerifyEmail: false,
      isPopupOpen: isPopUp,
      user: null,
    };
  },
  VERIFY: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isVerifyEmail: true,
      user,
    };
  },
  REGISTER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      isVerifyEmail: false,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
  ...initialState,
  method: "jwt",
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});
AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const initialize = async () => {
    setHeader();
    let isAuthenticated = false;
    let isVerifyEmail = false;
    let user = {};
    try {
      const response = await axios.post(API_PATH.profile, {
        get: "",
      });
      const { status, data } = response.data;
      user = data;
      if (status === "success") {
        isAuthenticated = Boolean(user.user.uid);
        // eslint-disable-next-line
        if (isAuthenticated && parseInt(user.user.isVerified, 10) === 1) {
          isVerifyEmail = true;
        }
      }

      if (
        status === "userid_expired" ||
        status === "userid_error" ||
        status === "invalid_profile"
      ) {
        isAuthenticated = false;
        setSession(null);
      }
    } catch (err) {
      // null
    }
    dispatch({
      type: "INITIALIZE",
      payload: {
        isAuthenticated,
        isVerifyEmail,
        user,
      },
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  const logout = async (isPopUp) => {
    if (isPopUp) {
      const currentUser = state.user.user;
      localStorage.setItem("popupName", currentUser.fname);
      localStorage.setItem("popupEmail", currentUser.email);
    } else {
      localStorage.removeItem("popupName");
      localStorage.removeItem("popupEmail");
    }
    logDevice(0);
    setSession(null);
    dispatch({ type: "LOGOUT", payload: { isPopUp } });
  };
  const login = async (username, password) => {
    const response = await axios.post(API_PATH.login, {
      username,
      password,
    });
    const { status, data, token } = response.data;
    if (status === "success") {
      setSession(token);
      const isVerifyEmail = parseInt(data.user.isVerified, 10) === 1;
      logDevice(1);
      dispatch({
        type: "LOGIN",
        payload: { user: data, isVerifyEmail },
      });
    }
    if (status === "invalid_login") {
      throw new Error(
        "Your username or password is incorrect! Please confirm and try again."
      );
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const logDevice = async (status) => {
    axios.post(API_PATH.login, {
      name: browserName,
      os: osName,
      deviceId: getUA,
      status,
      type: 0,
    });
  };

  const resetPassword = async (code, password) => {
    const response = await axios.post(API_PATH.forgot, {
      code,
      password,
    });
    const { status } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your password reset code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your password reset code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your password reset code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while resetting your password! Please confirm and try again."
        );
      }
    }
  };
  const forgotPassword = async (email) => {
    const response = await axios.post(API_PATH.forgot, {
      email,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Your email address or username is incorrect! Please confirm and try again."
      );
    }
  };
  const saveToken = async (saveToken) => {
    await axios.post(API_PATH.notifications, { saveToken, device: getUA });
  };
  const verifyAccount = async (code) => {
    const response = await axios.post(API_PATH.verify, { code });
    const { status, data } = response.data;
    if (status !== "success") {
      if (status === "expired_code") {
        throw new Error(
          "Your verification code has expired! Please request and try again."
        );
      } else if (status === "invalid_code") {
        throw new Error(
          "Your verification code is invalid! Please confirm and try again."
        );
      } else if (status === "used_code") {
        throw new Error(
          "Your verification code has already been used! Please confirm and try again."
        );
      } else {
        throw new Error(
          "Something went wrong while verifying your account! Please confirm and try again."
        );
      }
    } else {
      dispatch({ type: "VERIFY", payload: { user: data } });
    }
  };
  const sendVerifyCode = async () => {
    const response = await axios.post(API_PATH.verify, { request: "" });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error("Something went wrong! Please try again later.");
    }
  };
  const sendPasswordVerify = async () => {
    const { user } = state;
    const { usertype } = user.user;
    const uid = parseInt(usertype, 10) === 1 ? user.user.lid : user.user.uid;
    const response = await axios.post(API_PATH.profile, {
      generate_set_password: uid,
      type: usertype,
    });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "We cannot send your set password code at the moment! Please try again later."
      );
    }
  };
  const updateSettings = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      update_profile: "",
      ...values,
      photoURL: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status === "error") {
      throw new Error(
        "Something went wrong while updating your profile. Please try again later."
      );
    }
    if (status === "invalid_user") {
      logout();
    }
    if (status === "suspended") {
      throw new Error(
        "Your account has been placed on hold! Please contact support."
      );
    }
  };
  const getBanks = async () => {
    const response = await axios.post(API_PATH.misc, { banks: "" });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    return [];
  };
  const getAccountName = async (bank, number) => {
    const response = await axios.post(API_PATH.misc, {
      verify_account: "",
      bank,
      number,
    });
    const { status, data } = response.data;
    if (status === "success") {
      return data;
    }
    throw new Error("The account entered is invalid! Please confirm.");
  };
  const updateCompliance = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      compliance: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
      return;
    }
    if (status === "verified") {
      throw new Error("Your store is already verified!");
    }
    if (status === "invalid_data") {
      throw new Error(
        "The data you supplied is invalid or incorrect! Please confirm and try again."
      );
    }
    if (status === "server_down") {
      throw new Error(
        "The verification portal is down at the moment! Not to worry, we will try again as soon as possible!"
      );
    }
    throw new Error(
      "There is an issue with your compliance information! Please check your email for more information."
    );
  };
  const updateSettingsAccount = async (values) => {
    const response = await axios.post(API_PATH.settings, {
      ...values,
      bank: values.bank.code,
      bankName: values.bank.name,
      update_account: "",
    });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    }
    if (status !== "success") {
      throw new Error(
        "An error occurred while updating account! Please try again later."
      );
    }
  };
  const addSupport = async (values) => {
    const response = await axios.post(API_PATH.support, { add: "", ...values });
    const { status } = response.data;
    if (status !== "success") {
      throw new Error(
        "Something went wrong while processing your request! Please try again later."
      );
    }
  };
  const requestLink = async () => {
    const response = await axios.post(API_PATH.settings, { getLink: "" });
    const { status, data } = response.data;
    if (status === "success") {
      dispatch({ type: "UPDATE", payload: { user: data } });
    } else {
      throw new Error(
        "Something went wrong while generating your referral link! Please try again later."
      );
    }
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line
      value={{
        ...state,
        method: "jwt",
        saveToken,
        login,
        logout,
        updateSettings,
        sendPasswordVerify,
        getBanks,
        getAccountName,
        forgotPassword,
        resetPassword,
        updateSettingsAccount,
        addSupport,
        sendVerifyCode,
        verifyAccount,
        updateCompliance,
        initialize,
        requestLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
