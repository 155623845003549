// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/";
const ROOTS_DASHBOARD = "/";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  Login: path(ROOTS_AUTH, "login"),
  signup: "https://selpay.co/agent-signup",
  welcome: path(ROOTS_AUTH, "welcome"),
  forgotPassword: path(ROOTS_AUTH, "forgot-password"),
  resetPassword: path(ROOTS_AUTH, "reset-password"),
  verify: path(ROOTS_AUTH, "verify"),
  setPassword: path(ROOTS_AUTH, "set-password"),
};

export const PATH_PAGE = {
  page404: "404",
  errorOccured: "500",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, ""),
    settings: path(ROOTS_DASHBOARD, "settings"),
    compliance: path(ROOTS_DASHBOARD, "settings/compliance"),
    account: path(ROOTS_DASHBOARD, "settings/account"),
  },
  misc: {
    log: path(ROOTS_DASHBOARD, "log"),
    support: path(ROOTS_DASHBOARD, "support"),
    sendSupport: path(ROOTS_DASHBOARD, "support/send"),
    onboarding: path(ROOTS_DASHBOARD, "onboarding"),
  },
  business: {
    shops: path(ROOTS_DASHBOARD, "shops"),
    payments: path(ROOTS_DASHBOARD, "payments"),
  },
};

export const frontendPages = [
  "/login",
  "/signup",
  "/forgot-password",
  "/reset-password",
  "/404",
];
